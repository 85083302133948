<template>
  <v-row>
    <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
      <v-data-table
        :headers="TransactionHeaders"
        :items="transactionItems"
        dense
        :items-per-page="15"
        :loading="isLoading"
      >
        <template v-slot:[`item.credits_to_be_added`]={item}>
          <span v-text="item.credits_to_be_added?item.credits_to_be_added:'-'">
          </span>
        </template>
        <template v-slot:[`no-data`]>
          <v-alert class="gradient-bg2 white--text">No Data Available</v-alert>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" class="gradient-bg2 white--text">
            No records found!
          </v-alert>
        </template>
        <template v-slot:[`item.transaction_on`]="{ item }">
          <span>{{
            item.transaction_on != null ? get_date(item.transaction_on) : "N/A"
          }}</span>
        </template>
        <template v-slot:[`item.comment`]="{ item }">
          <span>{{ item.comment != null ? item.comment : "N/A" }}</span>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { GetTransactionOfOrganisation } from "@/graphql/queries.js";

export default {
  props: {
    OrgObj: Object,
  },
  data: () => ({
    overlay: false,
    isLoading: false,
    transactionItems: [],
    TransactionHeaders: [
      {
        text: "Amount Paid",
        value: "amount",
      },
      {
        text: "Credits Added",
        value: "credits_to_be_added",
      },
      {
        text: "Pack Selected",
        value: "pack_name",
      },
      {
        text: "Paid via",
        value: "payment_gateway",
      },
      {
        text: "Transacted On",
        value: "transaction_on",
      },
      {
        text: "Comments",
        value: "comment",
      },
    ],
  }),
  mounted() {
    this.viewTransactions(this.OrgObj);
  },
  methods: {
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    async viewTransactions(item) {
      this.transactionItems = [];
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GetTransactionOfOrganisation, {
            input: {
              organisation_id: item.organisation_id,
            },
          })
        );
        this.transactionItems = JSON.parse(
          result.data.GetTransactionOfOrganisation
        ).Items;
        // console.log(this.transactionItems);
        this.overlay = false;
        this.isLoading = false;
      } catch (err) {
        this.transactionItems = [];
        this.overlay = false;
        this.isLoading = false;
        console.log(err);
      }
    },
  },
};
</script>
<style></style>
