<template>
  <div>
    <v-card flat v-if="formActionType != 'initiate'">
      <!-- :flat="formActionType != undefined" -->
      <v-card-text>
        <v-row>
          <v-col cols="11">
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="time"
                  :label="
                    index.is_required == true
                      ? index.displayLabel + '*'
                      : index.displayLabel
                  "
                  @click="emit_edit_field(index)"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                v-model="time"
                full-width
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="1">
            <v-btn
              x-small
              v-if="formActionType != 'initiate' && formActionType != 'preview'"
              color="red"
              fab
              dark
              absolute
              @click="emit_fields((Toggle = 1), index)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat v-else-if="formActionType == 'preview'"> </v-card>
    <v-card flat v-else>
      <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            :label="
              index.is_required == true
                ? index.display_label + '*'
                : index.display_label
            "
            :rules="
              index.is_required == true ? [(v) => !!v || 'required '] : []
            "
            outlined
            readonly
            v-bind="attrs"
            v-on="on"
            @click="emit_edit_field(index)"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="time"
          full-width
          @click:minute="save_time(time, index.display_label, index.key)"
        ></v-time-picker>
      </v-menu>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    index: Object,
    formActionType: String,
  },
  data() {
    return {
      menu2: false,
      time: "",
    };
  },
  methods: {
    emit_fields(Toggle, index) {
      this.$emit("clicked", Toggle, index);
    },
    get_value(fieldvalue, fieldlabel, fieldKey) {
      var data = {
        label: fieldlabel,
        value: fieldvalue == "" ? "N/A" : fieldvalue,
        key: fieldKey,
      };

      this.$emit("timeInput", data);
    },
    save_time(time, fieldlabel, fieldKey) {
      this.$refs.menu.save(time);
      this.get_value(time, fieldlabel, fieldKey);
    },
    emit_edit_field(value) {
      this.$emit("editField", value);
    },
  },
};
</script>