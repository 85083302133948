/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const CreateUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUser) {
    CreateUser(input: $input)
  }
`;
export const DeleteUsers = /* GraphQL */ `
  mutation DeleteUsers($input: DeleteUsers) {
    DeleteUsers(input: $input)
  }
`;
export const CreateOrganisationByBms = /* GraphQL */ `
  mutation CreateOrganisationByBms($input: CreateOrganisationByBms) {
    CreateOrganisationByBms(input: $input)
  }
`;
export const UpdateReferralCreditsToOrganisation = /* GraphQL */ `
  mutation UpdateReferralCreditsToOrganisation(
    $input: UpdateReferralCreditsToOrganisation
  ) {
    UpdateReferralCreditsToOrganisation(input: $input)
  }
`;
export const CreateSalesRep = /* GraphQL */ `
  mutation CreateSalesRep($input: CreateSalesRep) {
    CreateSalesRep(input: $input)
  }
`;
export const DeleteReactiveSalesRep = /* GraphQL */ `
  mutation DeleteReactiveSalesRep($input: DeleteReactiveSalesRep) {
    DeleteReactiveSalesRep(input: $input)
  }
`;
export const UpdateSalesRep = /* GraphQL */ `
  mutation UpdateSalesRep($input: UpdateSalesRep) {
    UpdateSalesRep(input: $input)
  }
`;
export const CreateInpersinationUser = /* GraphQL */ `
  mutation CreateInpersinationUser($input: CreateInpersinationUser) {
    CreateInpersinationUser(input: $input)
  }
`;
export const AddCreditsOrganisation = /* GraphQL */ `
  mutation AddCreditsOrganisation($input: AddCreditsOrganisation) {
    AddCreditsOrganisation(input: $input)
  }
`;
export const GenerateQr = /* GraphQL */ `
  mutation GenerateQr($input: GenerateQr) {
    GenerateQr(input: $input)
  }
`;
export const EditQrStatusToPrinitigState = /* GraphQL */ `
  mutation EditQrStatusToPrinitigState($input: EditQrStatusToPrinitigState) {
    EditQrStatusToPrinitigState(input: $input) {
      message
      __typename
    }
  }
`;
export const EmailBodyEditForNoticeLowBalance = /* GraphQL */ `
  mutation EmailBodyEditForNoticeLowBalance(
    $input: EmailBodyEditForNoticeLowBalance
  ) {
    EmailBodyEditForNoticeLowBalance(input: $input)
  }
`;
export const UpdateSalesReferralCreditsToOrg = /* GraphQL */ `
  mutation UpdateSalesReferralCreditsToOrg(
    $input: UpdateSalesReferralCreditsToOrg
  ) {
    UpdateSalesReferralCreditsToOrg(input: $input)
  }
`;
export const AddReferredToOrganisation = /* GraphQL */ `
  mutation AddReferredToOrganisation($input: AddReferredToOrganisation) {
    AddReferredToOrganisation(input: $input)
  }
`;
export const EditOrganisationDetails = /* GraphQL */ `
  mutation EditOrganisationDetails($input: EditOrganisationDetails) {
    EditOrganisationDetails(input: $input)
  }
`;
export const updateMyPreznenceAppVersion = /* GraphQL */ `
  mutation UpdateMyPreznenceAppVersion(
    $input: UpdateMyPreznenceAppVersionInput
  ) {
    updateMyPreznenceAppVersion(input: $input)
  }
`;
export const updateAdminAppVersion = /* GraphQL */ `
  mutation UpdateAdminAppVersion($input: UpdateAdminAppVersionInput) {
    updateAdminAppVersion(input: $input)
  }
`;
export const customerDeactivateEdit = /* GraphQL */ `
  mutation CustomerDeactivateEdit($input: CustomerDeactivateEditInput) {
    customerDeactivateEdit(input: $input)
  }
`;
export const create_forms = /* GraphQL */ `
  mutation Create_forms($input: CreateFormsInput) {
    create_forms(input: $input)
  }
`;
export const delete_forms = /* GraphQL */ `
  mutation Delete_forms($input: DeleteFormsInput) {
    delete_forms(input: $input)
  }
`;
export const edit_forms = /* GraphQL */ `
  mutation Edit_forms($input: EditFormsInput) {
    edit_forms(input: $input)
  }
`;
export const signup_userV2 = /* GraphQL */ `
  mutation Signup_userV2($input: signup_userV2) {
    signup_userV2(input: $input)
  }
`;
export const add_sub_referrer = /* GraphQL */ `
  mutation Add_sub_referrer($input: add_sub_referrer) {
    add_sub_referrer(input: $input)
  }
`;
export const list_add_comments = /* GraphQL */ `
  mutation List_add_comments($input: listAddCommentsInput) {
    list_add_comments(input: $input)
  }
`;
export const generate_transactions_reports = /* GraphQL */ `
  mutation Generate_transactions_reports(
    $input: GenerateTransactionsReportsInput
  ) {
    generate_transactions_reports(input: $input)
  }
`;
export const create_banner = /* GraphQL */ `
  mutation Create_banner($input: CreateBannerInput) {
    create_banner(input: $input)
  }
`;
export const edit_banner = /* GraphQL */ `
  mutation Edit_banner($input: EditBannerInput) {
    edit_banner(input: $input)
  }
`;
export const delete_banner = /* GraphQL */ `
  mutation Delete_banner($input: DeleteBannerInput) {
    delete_banner(input: $input)
  }
`;
