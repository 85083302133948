var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","width":"700px"},model:{value:(_vm.viewSourceDialog),callback:function ($$v) {_vm.viewSourceDialog=$$v},expression:"viewSourceDialog"}},[_c('v-toolbar',{staticClass:"gradient-bg elevation-0",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("View Sources for Location - "+_vm._s(_vm.locationObject.location_name)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.close_dialog()}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")])],1)],1),_c('v-card',{staticClass:"overflow-hidden"},[_c('v-row',{staticClass:"mx-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-data-table',{staticClass:"overflow",attrs:{"headers":_vm.viewSourceData,"items":_vm.viewSourceItems,"loading":_vm.isLoading,"loading-text":"Loading... Please wait","dense":"","items-per-page":5},scopedSlots:_vm._u([{key:"item.qr_location_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.qr_location_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.qr_location_name))])])]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v("No Source(s) available for this Location ")])]},proxy:true},{key:"item.view_qr",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{on:{"click":function($event){return _vm.view_qr(item)}}},[_c('v-icon',_vm._g({staticClass:"primary--text mr-1",attrs:{"small":""}},on),[_vm._v("mdi-qrcode ")])],1)]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("View QR")])])]}}],null,true)})],1)],1)],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('ViewMemberQR',{attrs:{"viewMemberQRDialog":_vm.viewMemberQRDialog,"userDetailsObj":_vm.userDetailsObj},on:{"close_view_qr_dialog":function($event){_vm.viewMemberQRDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }