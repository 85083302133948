<template>
  <v-row>
    <Overlay :overlay="overlay" />
    <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
      <v-data-table
        :height="height"
        :headers="scanLogsData"
        :items="ScanLogsItems"
        dense
        :loading="isLoading"
        loading-text="Loading... Please wait"
        :options.sync="pagination"
        @update:options="getVerifyPayPagination()"
        :fixed-header="fixed"
      >
        <template v-slot:[`no-data`]>
          <v-alert class="gradient-bg2 white--text">
            No swipes registered yet.</v-alert
          >
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" class="gradient-bg2 white--text">
            No records found!
          </v-alert>
        </template>
        <template v-slot:[`item.swipe_date`]="{ item }">
          <span v-text="get_date2(item.swipe_time_stamp).split(',')[0]"></span>
        </template>
        <template v-slot:[`item.swipe_time`]="{ item }">
          <span v-text="get_date2(item.swipe_time_stamp).split(',')[1]"></span>
        </template>
        <template v-slot:[`item.contact_number`]="{ item }">
          <span
            v-text="
              item.user_contact_number
                ? item.user_country_code + '-' + item.user_contact_number
                : 'N/A'
            "
          ></span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
                :color="
                  item.source == 'CAMERA'
                    ? 'primary'
                    : item.source == 'WHATSAPP'
                    ? 'green'
                    : item.source == 'RESWIPE'
                    ? 'red'
                    : item.source == 'DATACORRECTION'
                    ? 'purple'
                    : item.source == 'PROXY'
                    ? 'yellow'
                    : item.source == 'BEACON'
                    ? 'blue'
                    : 'indigo'
                "
                v-text="
                  item.source == 'CAMERA'
                    ? 'mdi-camera'
                    : item.source == 'WHATSAPP'
                    ? 'mdi-whatsapp'
                    : item.source == 'RESWIPE'
                    ? 'mdi-redo-variant'
                    : item.source == 'DATACORRECTION'
                    ? 'mdi-human-edit'
                    : item.source == 'PROXY'
                    ? 'mdi-odnoklassniki'
                    : item.source == 'BOX'
                    ? 'mdi-card-account-details-outline'
                    : item.source == 'BEACON'
                    ? 'mdi-bluetooth-audio'
                    : item.source == 'DESKTOP'
                    ? 'mdi-desktop-classic'
                    : item.source == 'FACE'
                    ? 'mdi-account-box-outline'
                    : ''
                "
              ></v-icon>
            </template>
            <span class="white--text">
              {{
                item.source == "CAMERA"
                  ? "Source: QR"
                  : item.source == "WHATSAPP"
                  ? "Source: Whatsapp"
                  : item.source == "RESWIPE"
                  ? "Source: Re-Scan"
                  : item.source == "DATACORRECTION"
                  ? "Source: Data Correction"
                  : item.source == "PROXY"
                  ? "Source: PROXY"
                  : item.source == "FACE"
                  ? "Source: FACE"
                  : item.source == "BEACON"
                  ? "Source: BEACON"
                  : item.source == "BOX"
                  ? "Source: BOX"
                  : item.source == "DESKTOP"
                  ? "Source: DESKTOP"
                  : ""
              }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.geo`]="{ item }">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <a
                @click="
                  item.user_lat != undefined ||
                  item.user_long != undefined ||
                  item.user_lat != null ||
                  item.user_long != null
                    ? view_geo2(item)
                    : ''
                "
              >
                <v-icon
                  v-on="on"
                  small
                  :color="
                    item.user_lat != undefined ||
                    item.user_long != undefined ||
                    item.user_lat != null ||
                    item.user_long != null
                      ? 'primary'
                      : ''
                  "
                  v-text="
                    item.user_lat != undefined ||
                    item.user_long != undefined ||
                    item.user_lat != null ||
                    item.user_long != null
                      ? 'mdi-map-marker'
                      : 'mdi-map-marker-off'
                  "
                ></v-icon>
              </a>
            </template>
            <span
              class="white--text"
              v-if="
                item.user_lat != undefined ||
                item.user_long != undefined ||
                item.user_lat != null ||
                item.user_long != null
              "
              >View Geo</span
            >
            <span class="white--text" v-else> Geo not available</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.box_location`]="{ item }">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">
                {{ item.box_location }}
              </div>
            </template>
            <span class="white--text">{{ item.box_location }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.user_name`]="{ item }">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <div class="overflow" v-on="on">
                {{ item.user_name }}
              </div>
            </template>
            <span class="white--text">{{ item.user_name }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
    <ViewGeo
      @close_dialog="viewGeoDialog = false"
      :viewGeoDialog="viewGeoDialog"
      :geoObject="geoObject"
      :forScanLog="forScanLog"
    />
  </v-row>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import { API, graphqlOperation } from "aws-amplify";
import { getSwipesOfOrganisations } from "@/graphql/queries.js";
import ViewGeo from "@/components/Dialogs/Organisations/ViewGeo.vue";

export default {
  props: {
    OrgObj: Object,
  },
  components: {
    ViewGeo,
    Overlay,
  },
  data: () => ({
    forScanLog: true,
    height: 0,
    pagination: {
      itemsPerPage: 99,
      page: 1,
    },
    fixed: true,
    next_token: null,
    viewGeoDialog: false,
    geoObject: {},
    viewSourceDialog: false,
    locationObj: {},
    overlay: false,
    scanLogsData: [
      { text: "", value: "type" },
      { text: "Location Name", value: "box_location" },
      { text: "Member Name", value: "user_name" },
      { text: "Contact Number", value: "contact_number" },
      { text: "Swipe Date", value: "swipe_date" },
      { text: "Swipe Time", value: "swipe_time" },
      { text: "Geo", value: "geo" },
    ],
    ScanLogsItems: [],
    isLoading: false,
  }),
  mounted() {
    this.height = window.innerHeight - 250;
    this.get_swipes(this.OrgObj);
  },
  methods: {
    async get_swipes(item) {
      this.ScanLogsItems = [];
      this.overlay = true;
      this.isLoading = true;
      try {
        await API.graphql(
          graphqlOperation(getSwipesOfOrganisations, {
            input: {
              organisation_id: item.organisation_id,
              nextToken: null,
              limit: 101,
            },
          })
        ).then((res) => {
          this.ScanLogsItems = JSON.parse(
            res.data.getSwipesOfOrganisations
          ).swipes;
          this.next_token = JSON.parse(
            res.data.getSwipesOfOrganisations
          ).nexToken;
          this.$forceUpdate();
          this.overlay = false;
          this.isLoading = false;
        });
      } catch (err) {
        console.log("err", err);
        this.overlay = false;
        this.isLoading = false;
        this.ScanLogsItems = [];
      }
    },
    async getVerifyPayPagination() {
      this.overlay = true;
      try {
        if (this.next_token) {
          let result = await API.graphql(
            graphqlOperation(getSwipesOfOrganisations, {
              input: {
                organisation_id: this.OrgObj.organisation_id,
                limit: 100,
                nextToken: this.next_token,
              },
            })
          );
          let response = JSON.parse(
            result.data.getSwipesOfOrganisations
          ).swipes;
          this.ScanLogsItems.concat(response);
          this.ScanLogsItems = this.ScanLogsItems.concat(
            JSON.parse(result.data.getSwipesOfOrganisations).swipes
          );
          this.next_token = JSON.parse(
            result.data.getSwipesOfOrganisations
          ).nexToken;
          // console.log(this.next_token);
          this.overlay = false;
        } else {
          this.overlay = false;
        }
      } catch (err) {
        this.overlay = false;
        console.log(err);
      }
    },
    get_date2(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    view_geo2(item) {
      this.forScanLog = true;
      this.geoObject = item;
      this.viewGeoDialog = true;
    },
  },
};
</script>
<style></style>
