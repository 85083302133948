<template>
  <div>
    <v-card class="elevation-0 mx-3">
      <v-row no-gutters class="mt-3">
        <v-col cols="12" md="8">
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1 ml-n3">
                    <h4>Email Address:</h4>
                  </span>
                  <span v-text="` ${OrgObject.user_email_id}`"></span>
                </v-toolbar>
              </v-card-actions>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1">
                    <h4>Registered Type:</h4>
                  </span>
                  <span
                    v-text="` ${OrgObject.organisation_registered_type}`"
                  ></span>
                </v-toolbar>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1 ml-n3">
                    <h4>Created On:</h4>
                  </span>
                  <span
                    v-text="` ${get_date(OrgObject.organisation_created_on)}`"
                  ></span>
                </v-toolbar>
              </v-card-actions>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1">
                    <h4>Scan Distance in Meters:</h4>
                  </span>
                  <span
                    v-text="` ${OrgObject.organisation_qr_distance}`"
                  ></span>
                </v-toolbar>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1 ml-n3">
                    <h4>Contact Number:</h4>
                  </span>
                  <span
                    v-text="` ${OrgObject.user_full_contact_number}`"
                  ></span>
                </v-toolbar>
              </v-card-actions>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1">
                    <h4>Start Time Notification:</h4>
                  </span>
                  <span
                    :class="
                      OrgObject.is_start_time_notification == false
                        ? 'red--text'
                        : 'green--text'
                    "
                    v-text="` ${OrgObject.is_start_time_notification}`"
                  ></span>
                </v-toolbar>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1 ml-n3">
                    <h4>Country/State:</h4>
                  </span>
                  <span v-text="` ${OrgObject.organisation_country}`"></span> /
                  <span
                    v-text="
                      ` ${
                        OrgObject.organisation_state == undefined
                          ? '-'
                          : OrgObject.organisation_state
                      }`
                    "
                  ></span>

                  <v-btn
                    @click="changeContryy(OrgObject)"
                    class="gradient-bg white--text mx-3"
                    x-small
                    >Change</v-btn
                  >
                </v-toolbar>
              </v-card-actions>
            </v-col>
            <v-col cols="12" md="6">
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1">
                    <h4>End Time Notification:</h4>
                  </span>
                  <span
                    :class="
                      OrgObject.is_end_time_notification == false
                        ? 'red--text'
                        : 'green--text'
                    "
                    v-text="` ${OrgObject.is_end_time_notification}`"
                  ></span>
                </v-toolbar>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1 ml-n3">
                    <h4>Address:</h4>
                  </span>
                  <span>
                    {{
                      OrgObject.organisation_address == undefined ||
                      OrgObject.organisation_address == null
                        ? "-"
                        : OrgObject.organisation_address
                    }}</span
                  >
                  <v-btn
                    @click="changeAddress(OrgObject)"
                    class="gradient-bg white--text mx-3"
                    x-small
                    >Change</v-btn
                  >
                </v-toolbar>
              </v-card-actions>
            </v-col>
            <v-col cols="12" md="12">
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1 ml-n3">
                    <h4>GST Number:</h4>
                  </span>
                  <span
                    v-text="
                      `${
                        OrgObject.organisation_gst_number == undefined ||
                        OrgObject.organisation_gst_number == null
                          ? '-'
                          : OrgObject.organisation_gst_number
                      }`
                    "
                  ></span>
                  <v-btn
                    @click="changeGst(OrgObject)"
                    class="gradient-bg white--text mx-3"
                    x-small
                    >Change</v-btn
                  >
                </v-toolbar>
              </v-card-actions>
            </v-col>
            <v-col cols="12" md="12">
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1 ml-n3">
                    <h4>Referred By:</h4>
                  </span>
                  <span
                    v-text="
                      ` ${
                        OrgObject.referred_by == undefined ||
                        OrgObject.referred_by == null
                          ? '-'
                          : OrgObject.referred_by
                      }`
                    "
                  ></span>
                  <v-btn
                    @click="changeRef(OrgObject)"
                    class="gradient-bg white--text mx-3"
                    x-small
                    >Change</v-btn
                  >
                </v-toolbar>
              </v-card-actions>
            </v-col>
            <!-- <v-row>
              <v-col cols="6">
                <v-select
                  v-model="selectedCountry"
                  :items="countries"
                  item-text="name"
                  item-value="isoCode"
                  label="Select Country"
                  @change="filterStates"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="selectedState"
                  :items="filteredStates"
                  item-text="name"
                  item-value="name"
                  label="Select State"
                ></v-select>
              </v-col>
            </v-row> -->
            <!-- v-show="  OrgObject.referred_by != undefined &&
                        OrgObject.referred_by != null" -->
            <!-- <v-col
              cols="12"
              md="12"
              v-show="
                OrgObject.referred_by != undefined &&
                OrgObject.referred_by != null
              "
            >
              <v-card-actions class="ma-0 pa-0">
                <v-toolbar dense elevation="0">
                  <span class="mr-1 ml-n3">
                    <h4>Sub Referred By:</h4>
                  </span>
                  <u>
                    <span
                      @click="changesubRef(OrgObject)"
                      class="blue--text"
                      v-text="
                        ` ${
                          OrgObject.sub_referrers == undefined ||
                          OrgObject.sub_referrers == null
                            ? '-'
                            : OrgObject.sub_referrers.length
                        }`
                      "
                    ></span
                  ></u> -->
            <!-- <v-btn
                    @click="changesubRef(OrgObject)"
                    class="gradient-bg white--text mx-3"
                    x-small
                    >Change</v-btn
                  > -->
            <!-- </v-toolbar>
              </v-card-actions>
            </v-col> -->
          </v-row>
        </v-col>
        <v-col class="mt-n16" cols="12" md="4">
          <v-tabs fixed-tab color="red" v-model="tabOrgDetails">
            <v-tab> Credits Report</v-tab>
            <v-tab> Work Timings </v-tab>
          </v-tabs>
          <div class="mt-7" v-if="tabOrgDetails == 0">
            <v-row>
              <v-col cols="12" md="12">
                <v-toolbar dense elevation="0">
                  <!-- <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        dense
                        outlined
                        readonly
                        class="mr-2 ml-n5"
                        v-model="CreditsAvailable"
                      ></v-text-field>
                    </template>
                    <span class="white--text">
                      {{ CreditsAvailable }}
                    </span>
                  </v-tooltip> -->
                  <!-- <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        dense
                        outlined
                        class="mr-2"
                        readonly
                        v-model="CreditsConsumed"
                      ></v-text-field>
                    </template>
                    <span class="white--text">
                      {{ CreditsConsumed }}
                    </span>
                  </v-tooltip> -->
                  <div class="mt-n7">{{ CreditsAvailable }}</div>
                  <div class="ml-2 mr-2 mt-n7">{{ CreditsConsumed }}</div>
                  <v-btn
                    @click="addCredits(OrgObject)"
                    class="gradient-bg white--text mt-n7 mr-n5"
                    small
                    v-if="$vuetify.breakpoint.name != 'md'"
                    >Add Credits
                  </v-btn>
                  <v-tooltip color="primary" bottom v-else>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="addCredits(OrgObject)"
                        class="gradient-bg white--text mt-n7"
                        small
                      >
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <span class="white--text"> Add Credits </span>
                  </v-tooltip>
                </v-toolbar>
              </v-col>
            </v-row>
            <PolarChart
              :height="450"
              :data="chartData"
              :options="chartOptions"
              :count="chart_count"
            ></PolarChart>
          </div>
          <div v-if="tabOrgDetails == 1">
            <v-card class="elevation-2">
              <v-row no-gutters>
                <v-col cols="12">
                  <div class="mt-5 mb-n5">
                    <v-select
                      class="mx-3"
                      v-model="workshift_item"
                      :items="workshift_list"
                      dense
                      outlined
                      item-text="workshift_name"
                      item-value="workshift_name"
                      label="Select Shift to view WorkTimings"
                      return-object
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
              <div v-show="workshift_item">
                <v-row>
                  <v-col cols="12" sm="8" md="8" xs="8" lg="8" xl="8"></v-col>
                  <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
                    <v-card-text align="center" class="mt-1 mb-n7 ml-3"
                      ><strong> Weekly Off</strong></v-card-text
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    xs="3"
                    lg="3"
                    xl="3"
                    class="mt-3 ml-9 mr-n9"
                  >
                    <strong> Monday </strong></v-col
                  >
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    xs="3"
                    lg="3"
                    xl="3"
                    class="mt-3"
                  >
                    <v-text-field
                      v-model="current_workshift_for_shift.Monday.start_time"
                      label="Start Time"
                      class="mx-2"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      :disabled="current_workshift_for_shift.Monday.weekly_off"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    xs="3"
                    lg="3"
                    xl="3"
                    class="mt-3"
                  >
                    <v-text-field
                      class="mx-2"
                      v-model="current_workshift_for_shift.Monday.end_time"
                      label="End Time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      :disabled="current_workshift_for_shift.Monday.weekly_off"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                    xs="2"
                    lg="2"
                    xl="2"
                    class="mt-3"
                  >
                    <v-toolbar dense flat>
                      <v-spacer />
                      <v-checkbox
                        class="mt-3"
                        :v-model="current_workshift_for_shift.Monday.weekly_off"
                        readonly
                      ></v-checkbox>
                    </v-toolbar>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    xs="3"
                    lg="3"
                    xl="3"
                    class="mt-3 ml-9 mr-n9"
                  >
                    <strong>Tuesday </strong></v-col
                  >
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      v-model="current_workshift_for_shift.Tuesday.start_time"
                      label="Start Time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      class="mx-2"
                      :disabled="current_workshift_for_shift.Tuesday.weekly_off"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      v-model="current_workshift_for_shift.Tuesday.end_time"
                      label="End Time"
                      class="mx-2"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      :disabled="current_workshift_for_shift.Tuesday.weekly_off"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-toolbar dense flat>
                      <v-spacer />
                      <v-checkbox
                        readonly
                        :v-model="
                          current_workshift_for_shift.Tuesday.weekly_off
                        "
                        class="mt-3"
                      ></v-checkbox>
                    </v-toolbar>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    xs="3"
                    lg="3"
                    xl="3"
                    class="mt-3 ml-9 mr-n9"
                  >
                    <strong> Wednesday </strong></v-col
                  >
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      class="mx-2"
                      v-model="current_workshift_for_shift.Wednesday.start_time"
                      label="Start Time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      :disabled="
                        current_workshift_for_shift.Wednesday.weekly_off
                      "
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      class="mx-2"
                      v-model="current_workshift_for_shift.Wednesday.end_time"
                      label="End Time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      :disabled="
                        current_workshift_for_shift.Wednesday.weekly_off
                      "
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-toolbar dense flat>
                      <v-spacer />
                      <v-checkbox
                        :v-model="
                          current_workshift_for_shift.Wednesday.weekly_off
                        "
                        readonly
                        class="mt-3"
                      ></v-checkbox></v-toolbar
                  ></v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    xs="3"
                    lg="3"
                    xl="3"
                    class="mt-3 ml-9 mr-n9"
                  >
                    <strong>Thursday</strong>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      class="mx-2"
                      v-model="current_workshift_for_shift.Thursday.start_time"
                      label="Start Time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      :disabled="
                        current_workshift_for_shift.Thursday.weekly_off
                      "
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      class="mx-2"
                      v-model="current_workshift_for_shift.Thursday.end_time"
                      label="End Time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      dense
                      :disabled="
                        current_workshift_for_shift.Thursday.weekly_off
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-toolbar dense flat>
                      <v-spacer />
                      <v-checkbox
                        :v-model="
                          current_workshift_for_shift.Thursday.weekly_off
                        "
                        class="mt-3"
                        readonly
                      ></v-checkbox> </v-toolbar
                  ></v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    xs="3"
                    lg="3"
                    xl="3"
                    class="mt-3 ml-9 mr-n9"
                  >
                    <strong>Friday </strong></v-col
                  >
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      v-model="current_workshift_for_shift.Friday.start_time"
                      label="Start Time"
                      class="mx-2"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      :disabled="current_workshift_for_shift.Friday.weekly_off"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      v-model="current_workshift_for_shift.Friday.end_time"
                      label="End Time"
                      class="mx-2"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      dense
                      :disabled="current_workshift_for_shift.Friday.weekly_off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-toolbar dense flat>
                      <v-spacer />
                      <v-checkbox
                        :v-model="current_workshift_for_shift.Friday.weekly_off"
                        readonly
                        class="mt-3"
                      ></v-checkbox>
                    </v-toolbar>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    xs="3"
                    lg="3"
                    xl="3"
                    class="mt-3 ml-9 mr-n9"
                  >
                    <strong> Saturday </strong></v-col
                  >
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      v-model="current_workshift_for_shift.Saturday.start_time"
                      label="Start Time"
                      class="mx-2"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      :disabled="
                        current_workshift_for_shift.Saturday.weekly_off
                      "
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      v-model="current_workshift_for_shift.Saturday.end_time"
                      class="mx-2"
                      label="End Time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      dense
                      :disabled="
                        current_workshift_for_shift.Saturday.weekly_off
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                    <v-toolbar dense flat>
                      <v-spacer />
                      <v-checkbox
                        readonly
                        v-model="
                          current_workshift_for_shift.Saturday.weekly_off
                        "
                        class="mt-3"
                      ></v-checkbox>
                    </v-toolbar>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    xs="3"
                    lg="3"
                    xl="3"
                    class="mt-3 ml-9 mr-n9"
                  >
                    <strong>Sunday </strong></v-col
                  >
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      v-model="current_workshift_for_shift.Sunday.start_time"
                      label="Start Time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      class="mx-2"
                      :disabled="current_workshift_for_shift.Sunday.weekly_off"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      v-model="current_workshift_for_shift.Sunday.end_time"
                      label="End Time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      class="mx-2"
                      dense
                      :disabled="current_workshift_for_shift.Sunday.weekly_off"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                    xs="2"
                    lg="2"
                    xl="2"
                    class="mb-5"
                  >
                    <v-toolbar dense flat>
                      <v-spacer />
                      <v-checkbox
                        v-model="current_workshift_for_shift.Sunday.weekly_off"
                        class="mt-3"
                        readonly
                      ></v-checkbox>
                    </v-toolbar>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />
    <ViewGeo
      @close_dialog="viewGeoDialog = false"
      :viewGeoDialog="viewGeoDialog"
      :geoObject="geoObject"
      :forScanLog="forScanLog"
    />
    <AddCredits
      :creditsdialog="creditsdialog"
      :CreditsObject="CreditsObject"
      @closedialog="creditsdialog = false"
      @close_get_org="close_add_credits_refresh_org"
    />
    <Overlay :overlay="overlay" />
    <ChangeReferrals
      :changeRefDialog="changeRefDialog"
      :refObj="RefObject"
      @re_render="$emit('re_render', 'from_change_ref')"
      @close_dialog="closeChangeRef()"
    />
    <ChangeSubreferrals
      :referralSub="referralSub"
      :refObj="RefObject"
      @re_render="$emit('re_render', 'from_change_ref')"
      @close_dialog="referralSub = false"
    />
    <ViewSourcesForLocation
      :viewSourceDialog="viewSourceDialog"
      :locationObject="locationObj"
      @close_view_src_dialog="viewSourceDialog = !viewSourceDialog"
    />
    <ChangeAddress
      :ChgAddressObj="ChgAddressObj"
      :changeAddressDialog="changeAddressDialog"
      @re_render="$emit('re_render', 'from_change_ref')"
      @close_dialog="changeAddressDialog = false"
    />
    <ChangeGst
      :ChgGstObj="ChgGstObj"
      :changeGstDialog="changeGstDialog"
      @re_render="$emit('re_render', 'from_change_ref')"
      @close_dialog="changeGstDialog = false"
    />
    <ChangeCountry
      :changeCountryDialog="changeCountryDialog"
      :refObj="RefObject"
      @re_render="$emit('re_render', 'from_change_ref')"
      @close_dialog="closeChangecontry()"
    />
  </div>
</template>

<script>
import { Country, State } from "country-state-city";
import PolarChart from "@/components/Charts/PolarChart.js";
import { API, graphqlOperation } from "aws-amplify";
import {
  GetAllAdminsOfPortal,
  GetAllMembersByOrganisationId,
  GetAllLocations,
  GetTransactionOfOrganisation,
  getSwipesOfOrganisations,
  getWorkShiftForOrganisation,
} from "@/graphql/queries.js";
import SnackBar from "@/components/SnackBar.vue";
import Overlay from "@/components/Overlay.vue";
import ViewGeo from "@/components/Dialogs/Organisations/ViewGeo.vue";
import AddCredits from "@/components/Dialogs/Credits/AddCredits.vue";
import ChangeReferrals from "@/components/Dialogs/Referrals/ChangeReferrals.vue";
import ViewSourcesForLocation from "@/components/Dialogs/Organisations/OrganisationDetails/ViewSourcesForLocation.vue";
import ChangeAddress from "@/components/Dialogs/Organisations/OrganisationDetails/ChangeAddress.vue";
import ChangeGst from "@/components/Dialogs/Organisations/OrganisationDetails/ChangeGst.vue";
import ChangeSubreferrals from "@/components/Dialogs/Referrals/ChangeSubreferrals.vue";
import ChangeCountry from "@/components/Dialogs/Organisations/ChangeCountry.vue";

export default {
  props: {
    OrgObject: Object,
    type: String,
    tab: Number,
    openView: String,
    firstMount: Boolean,
  },
  components: {
    PolarChart,
    SnackBar,
    Overlay,
    ViewGeo,
    AddCredits,
    ChangeReferrals,
    ViewSourcesForLocation,
    ChangeAddress,
    ChangeGst,
    ChangeSubreferrals,
    ChangeCountry,
  },
  data: () => ({
    workshift_item: "",
    countries: [],
    states: [],
    selectedCountry: "",
    selectedState: "",
    current_workshift_for_shift: {
      Friday: "",
      Monday: "",
      Saturday: "",
      Sunday: "",
      Thursday: "",
      Tuesday: "",
      Wednesday: "",
    },
    workshift_list: [],
    CreditsAvailable: "",
    CreditsConsumed: "",
    tabOrgDetails: null,
    height: 0,
    pagination: {
      itemsPerPage: 99,
      page: 1,
    },
    fixed: true,
    next_token: null,
    referralSub: false,
    ChgGstObj: {},
    changeGstDialog: false,
    ChgAddressObj: {},
    changeAddressDialog: false,
    forScanLog: false,
    currentView: "",
    viewSourceDialog: false,
    locationObj: {},
    changeRefDialog: false,
    changeCountryDialog: false,
    action_count: 0,
    chart_count: 0,
    count: 0,
    creditsdialog: false,
    viewGeoDialog: false,
    isLoading: false,
    overlay: false,
    SnackBarComponent: {},
    geoObject: {},
    scanLogsData: [
      { text: "", value: "type" },
      { text: "Location Name", value: "box_location" },
      { text: "Member Name", value: "user_name" },
      { text: "Contact Number", value: "contact_number" },
      { text: "Swipe Date", value: "swipe_date" },
      { text: "Swipe Time", value: "swipe_time" },
      { text: "Geo", value: "geo" },
    ],
    ScanLogsItems: [],
    adminItems: [],
    adminHeaders: [
      {
        text: "E-Mail",
        value: "user_email_id",
        sortable: false,
      },
      {
        text: "Created On",
        value: "user_created_on",
        sortable: false,
      },
      {
        text: "DT / AV",
        value: "admin_device_type",
        tooltip_text: "Device Type / App Version",
        sortable: false,
      },
    ],
    memberItems: [],
    locationHeaders: [
      {
        text: "Location Name",
        value: "location_name",
      },
      {
        text: "Created On",
        value: "location_created_on",
      },
      {
        text: "#Sources",
        value: "no_of_source",
      },
      {
        text: "Actions",
        value: "Actions",
      },
    ],
    locationItems: [],
    memberHeaders: [
      {
        text: "Member Id",
        value: "employee_id",
        sortable: false,
      },
      {
        text: "Member Name",
        value: "user_name",
        sortable: false,
      },
      {
        text: "Contact Number",
        value: "user_full_contact_number",
        sortable: false,
      },
      {
        text: "Created On",
        value: "user_created_on",
        sortable: false,
      },
      {
        text: "DT / AV",
        value: "device_type",
        tooltip_text: "Device Type / App Version",
        sortable: false,
      },
    ],
    transactionItems: [],
    TransactionHeaders: [
      {
        text: "Amount Paid",
        value: "amount",
      },
      {
        text: "Credits Added",
        value: "credits_to_be_added",
      },
      {
        text: "Pack Selected",
        value: "pack_name",
      },
      {
        text: "Paid via",
        value: "payment_gateway",
      },
      {
        text: "Transacted On",
        value: "transaction_on",
      },
      {
        text: "Comments",
        value: "comment",
      },
    ],
    chartOptions: {
      hoverBorderWidth: 20,
      responsive: true,
      maintainAspectRatio: false,
    },
    chartData: {
      hoverBackgroundColor: "red",
      hoverBorderWidth: 10,
      labels: [
        "QR Scan",
        "Face Scan",
        "Data Correction",
        "Proxy",
        "Desktop",
        "Box",
        "Forms",
        "RFID",
        "Beacon",
        "Simple",
        "Live Tracking",
      ],
      // labels: ["Camera", "ReSwipe", "WhatsApp", "Data Correction", "Proxy","Face","Desktop","Box"],
      datasets: [
        {
          label: "Consumed Credits",
          backgroundColor: [
            "#3cba9f",
            "#F44336",
            "#0000FF",
            "#FFFF00",
            "#FF9800",
            "#d90fa6",
            "#4A148C",
            "#827717",
            "#800000",
            "#64DD17",
            "#BF360C",
          ],
          data: [],
        },
      ],
    },
    CreditsObject: {},
    RefObject: {},
  }),
  computed: {
    filteredStates() {
      return this.states.filter(
        (state) => state.countryCode === this.selectedCountry
      );
    },
  },
  mounted() {
    console.log(this.OrgObject, "hh");
    this.countries = Country.getAllCountries();
    this.states = State.getAllStates();
    this.CreditsAvailable =
      "Available: " + this.OrgObject.organisation_total_credits;
    this.CreditsConsumed =
      this.OrgObject.total_consumed_credits != null ||
      this.OrgObject.total_consumed_credits != undefined
        ? "Consumed: " + this.OrgObject.total_consumed_credits
        : "Consumed: " + 0;
    this.height = window.innerHeight - 350;
    if (this.firstMount != true) {
      if (this.openView == "LOCATION") {
        this.overlay = true;
        this.action_count = 0;
        this.chart_count = 0;
        this.get_locations(this.OrgObject, "LOCATION");
      } else if (this.openView == "MEMBER") {
        this.overlay = true;
        this.action_count = 0;
        this.chart_count = 0;
        this.get_members(this.OrgObject, "MEMBER");
      } else if (this.openView == "ADMIN") {
        this.overlay = true;
        this.action_count = 0;
        this.chart_count = 0;
        this.get_admins(this.OrgObject, "ADMIN");
      } else if (this.openView == "TRANSACTION") {
        this.overlay = true;
        this.action_count = 0;
        this.chart_count = 0;
        this.viewTransactions(this.OrgObject, "TRANSACTION");
      } else if (this.openView == "SCANLOG") {
        this.overlay = true;
        this.action_count = 0;
        this.chart_count = 0;
        this.get_swipes();
      } else if (this.openView == "WORKSHIFT") {
        this.overlay = true;
        this.action_count = 0;
        this.chart_count = 0;
        this.getWorkShift();
      } else {
        this.overlay = true;
        this.action_count = 0;
        this.chart_count = 0;
        this.view_scans(this.OrgObject);
      }
    } else {
      this.firstMount = false;
      this.overlay = true;
      this.action_count = 0;
      this.chart_count = 0;
      this.view_scans(this.OrgObject);
    }
  },
  destroyed() {
    this.$emit("currentView", this.currentView);
  },
  watch: {
    tab(val) {
      if (val == 1) {
        this.type = "";
        this.count = 0;
        this.action_count = 0;
      }
    },
    tabOrgDetails(val) {
      if (val == 1) {
        this.get_workshift(this.OrgObject);
      }
    },
    workshift_item(val) {
      // console.log(val);
      this.current_workshift_for_shift = val.workshift_timings;
    },
  },
  methods: {
    filterStates() {
      // Filter states based on the selected country
      this.selectedState = ""; // Reset selected state
    },
    async get_workshift(item) {
      // console.log("i", item);
      try {
        let result = await API.graphql(
          graphqlOperation(getWorkShiftForOrganisation, {
            input: {
              organisation_id: item.organisation_id,
            },
          })
        );
        // this.locationItems = JSON.parse(
        //   result.data.GetAllLocations
        // ).totallocations.Items;
        // console.log(JSON.parse(result.data.getWorkShiftForOrganisation).Items);
        this.workshift_list = JSON.parse(
          result.data.getWorkShiftForOrganisation
        ).Items;
        // console.log("workshift_items", this.workshift_items);
        this.isLoading = false;
        this.overlay = false;
        this.$forceUpdate();
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
    get_date2(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    closeChangeRef() {
      this.changeRefDialog = !this.changeRefDialog;
    },
    closeChangecontry() {
      this.changeCountryDialog = !this.changeCountryDialog;
    },
    changeRef(item) {
      this.RefObject = {};
      this.RefObject = item;
      this.changeRefDialog = true;
    },
    changeContryy(item) {
      this.RefObject = {};
      this.RefObject = item;
      this.changeCountryDialog = true;
    },
    changesubRef(item) {
      this.RefObject = {};
      this.RefObject = item;
      this.referralSub = true;
    },
    changeAddress(item) {
      (this.ChgAddressObj = {}), (this.ChgAddressObj = item);
      this.changeAddressDialog = true;
    },
    changeGst(item) {
      (this.ChgGstObj = {}), (this.ChgGstObj = item);
      this.changeGstDialog = true;
    },
    view_scans(OrgObject) {
     // console.log(this.OrgObject);
      this.currentView = "";
      var consumedSwipes = [];
      consumedSwipes.push(OrgObject.organisation_total_camera_swipes);
      consumedSwipes.push(OrgObject.organisation_total_face_swipes);
      // consumedSwipes.push(OrgObject.organisation_total_reswipes);
      // consumedSwipes.push(OrgObject.organisation_total_whatsapp_swipes);
      consumedSwipes.push(OrgObject.organisation_total_datacorrections);
      consumedSwipes.push(OrgObject.organisation_total_proxy_swipes);

      consumedSwipes.push(OrgObject.organisation_total_desktop_swipes);
      consumedSwipes.push(OrgObject.organisation_total_boxes);
      consumedSwipes.push(OrgObject.no_forms_submission);
      consumedSwipes.push(OrgObject.organisation_total_rfid_swipes);
      consumedSwipes.push(OrgObject.organisation_total_beacon_swipes);
      consumedSwipes.push(OrgObject.organisation_total_simple_swipes);
      consumedSwipes.push(OrgObject.organisation_total_live_swipes);
      
       
      this.chartData.datasets[0].data = consumedSwipes;
      this.overlay = false;
      this.$forceUpdate();
      this.action_count = 0;
      this.chart_count = 1;
    },
    close_add_credits_refresh_org() {
      this.creditsdialog = false;
      this.$emit("close_org_dialog_open_again");
    },
    addCredits(item) {
      this.CreditsObject = {};
      this.CreditsObject = item;
      this.creditsdialog = true;
    },
    view_geo(item) {
      this.forScanLog = false;
      this.geoObject = item;
      this.viewGeoDialog = true;
    },
    view_geo2(item) {
      this.forScanLog = true;
      this.geoObject = item;
      this.viewGeoDialog = true;
    },
    async get_locations(item, type) {
      this.currentView = type;
      this.chart_count = 0;
      this.type = "";
      this.type = type;
      this.memberItems = [];
      this.adminItems = [];
      this.locationItems = [];
      this.isLoading = true;
      this.overlay = true;
      this.action_count = 1;
      try {
        let result = await API.graphql(
          graphqlOperation(GetAllLocations, {
            input: {
              organisation_id: item.organisation_id,
            },
          })
        );
        this.locationItems = JSON.parse(
          result.data.GetAllLocations
        ).totallocations.Items;
        this.isLoading = false;
        this.overlay = false;
        this.$forceUpdate();
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
    async get_members(item, type) {
      this.currentView = type;
      this.chart_count = 0;
      this.type = "";
      this.type = type;
      this.memberItems = [];
      this.locationItems = [];
      this.adminItems = [];
      this.isLoading = true;
      this.overlay = true;
      this.action_count = 1;
      try {
        let result = await API.graphql(
          graphqlOperation(GetAllMembersByOrganisationId, {
            input: {
              organisation_id: item.organisation_id,
            },
          })
        );
        this.memberItems = JSON.parse(
          result.data.GetAllMembersByOrganisationId
        ).data.Items;
        this.isLoading = false;
        this.overlay = false;
        this.$forceUpdate();
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
      // this.emitCurrentView("MEMBERS");
    },
    async get_admins(item, type) {
      this.currentView = type;
      this.chart_count = 0;
      this.type = "";
      this.type = type;
      this.memberItems = [];
      this.adminItems = [];
      this.locationItems = [];
      this.isLoading = true;
      this.overlay = true;
      this.action_count = 1;
      try {
        let result = await API.graphql(
          graphqlOperation(GetAllAdminsOfPortal, {
            input: {
              organisation_id: item.organisation_id,
            },
          })
        );

        this.adminItems = JSON.parse(
          result.data.GetAllAdminsOfPortal
        ).data.items;
        this.isLoading = false;
        this.overlay = false;
        this.$forceUpdate();
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
      // this.emitCurrentView("ADMINS");
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    async viewTransactions(item, type) {
      this.currentView = type;
      this.chart_count = 0;
      this.type = "";
      this.type = type;
      this.action_count = 1;
      try {
        let result = await API.graphql(
          graphqlOperation(GetTransactionOfOrganisation, {
            input: {
              organisation_id: item.organisation_id,
            },
          })
        );
        this.transactionItems = JSON.parse(
          result.data.GetTransactionOfOrganisation
        ).Items;
        this.overlay = false;
      } catch (err) {
        console.log(err);
      }
      // this.emitCurrentView("TRANSACTIONS");
    },
    getWorkShift() {
      this.currentView = "WORKSHIFT";
      this.chart_count = 0;
      this.action_count = 2;
      this.overlay = false;
    },
    getLocationSources(item) {
      this.locationObj = item;
      this.viewSourceDialog = true;
    },
    async get_swipes() {
      this.currentView = "SCANLOG";
      this.chart_count = 0;
      this.action_count = 3;
      this.ScanLogsItems = [];
      this.overlay = true;
      this.isLoading = true;
      try {
        await API.graphql(
          graphqlOperation(getSwipesOfOrganisations, {
            input: {
              organisation_id: this.OrgObject.organisation_id,
              nextToken: null,
              limit: 101,
            },
          })
        ).then((res) => {
          this.ScanLogsItems = JSON.parse(
            res.data.getSwipesOfOrganisations
          ).swipes;
          this.next_token = JSON.parse(
            res.data.getSwipesOfOrganisations
          ).nexToken;
          this.$forceUpdate();
          this.overlay = false;
          this.isLoading = false;
        });
      } catch (err) {
        console.log("err", err);
        this.overlay = false;
        this.isLoading = false;
        this.ScanLogsItems = [];
      }
    },
    async getVerifyPayPagination() {
      try {
        if (this.next_token) {
          let result = await API.graphql(
            graphqlOperation(getSwipesOfOrganisations, {
              input: {
                organisation_id: this.OrgObject.organisation_id,
                limit: 100,
                nextToken: this.next_token,
              },
            })
          );
          let response = JSON.parse(
            result.data.getSwipesOfOrganisations
          ).swipes;
          this.ScanLogsItems.concat(response);
          this.ScanLogsItems = this.ScanLogsItems.concat(
            JSON.parse(result.data.getSwipesOfOrganisations).swipes
          );
          this.next_token = JSON.parse(
            result.data.getSwipesOfOrganisations
          ).nexToken;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style></style>
