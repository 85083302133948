<template>
  <div>
    <v-dialog v-model="changeAddressDialog" max-width="700" persistent>
      <v-card>
        <v-toolbar class="gradient-bg" dense>
          <v-toolbar-title class="white--text">
            Change Address - {{ ChgAddressObj.organisation_name }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn class="white--text" @click="closeDialog()" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card class="overflow-hidden">
          <v-form ref="form">
            <v-row>
              <v-textarea
                dense
                outlined
                label="Change Address"
                v-model="currentAddress"
                rows="2"
                class="mx-7 mt-7"
                :rules="addressRules"
              ></v-textarea>
            </v-row>
            <v-row justify="center" class="mt-n3">
              <v-btn
                @click="changeAddress()"
                class="gradient-bg white--text mb-7"
                small
                >Confirm</v-btn
              >
            </v-row>
          </v-form>
        </v-card>
      </v-card>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { EditOrganisationDetails } from "@/graphql/mutations.js";
import SnackBar from "@/components/SnackBar.vue";

export default {
  components: {
    SnackBar,
  },
  props: {
    changeAddressDialog: Boolean,
    ChgAddressObj: Object,
  },
  data: () => ({
    overlay: false,
    SnackBarComponent: {},
    currentAddress: "",
    addressRules: [
      (v) => !!v || "Address is required",
      // (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),
  watch: {
    changeAddressDialog(val) {
      if (val == true) {
        this.currentAddress = this.ChgAddressObj.organisation_address;
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close_dialog");
    },
    async changeAddress() {
      if (this.$refs.form.validate()) {
        try {
          await API.graphql(
            graphqlOperation(EditOrganisationDetails, {
              input: {
                organisation_id: this.ChgAddressObj.organisation_id,
                organisation_address: this.currentAddress,
              },
            })
          ).then((res) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: JSON.parse(res.data.EditOrganisationDetails)
                .Message,
            };
            this.$emit("re_render");
            this.closeDialog();
          });
        } catch (err) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText:
              err.errors[0].message == "Empty field occured"
                ? "Please Enter Address!"
                : err.errors[0].message,
          };
        }
      }
    },
  },
};
</script>

<style></style>
