var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.formActionType != 'initiate')?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":_vm.index.is_required == true
                    ? _vm.index.displayLabel + '*'
                    : _vm.index.displayLabel,"readonly":"","outlined":""},on:{"click":function($event){return _vm.emit_edit_field(_vm.index)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}],null,false,3982362385),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"1"}},[(_vm.formActionType != 'initiate' && _vm.formActionType != 'preview')?_c('v-btn',{attrs:{"x-small":"","color":"red","fab":"","dark":"","absolute":""},on:{"click":function($event){_vm.emit_fields((_vm.Toggle = 1), _vm.index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)],1)],1):(_vm.formActionType == 'preview')?_c('v-card',{attrs:{"flat":""}}):_c('v-card',{attrs:{"flat":""}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.index.is_required == true
              ? _vm.index.display_label + '*'
              : _vm.index.display_label,"rules":_vm.index.is_required == true ? [function (v) { return !!v || 'required '; }] : [],"outlined":"","readonly":""},on:{"click":function($event){return _vm.emit_edit_field(_vm.index)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.save_time(_vm.time, _vm.index.display_label, _vm.index.key)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }