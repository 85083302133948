<template>
  <div>
    <v-card flat v-if="formActionType != 'initiate'">
      <!-- :flat="formActionType != undefined" -->
      <v-card-text>
        <v-row>
          <v-col cols="11">
            <v-card @click="emit_edit_field(index)" flat>
              <div class="text-left">
                {{ index.displayLabel }}

                <v-rating
                  v-model="rating"
                  background-color="indigo lighten-3"
                  color="#f4813f"
                  size="50"
                  readonly
                ></v-rating>
              </div>
            </v-card>
          </v-col>
          <v-col cols="1">
            <v-btn
              x-small
              color="red"
              v-if="
                formActionType != 'initiate' &&
                formActionType != 'preview' &&
                formActionType != 'previewproof'
              "
              fab
              dark
              right
              @click="emit_fields((Toggle = 1), index)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat v-else>
      <div class="text-left">
        {{
          index.is_required == true
            ? index.display_label + "*"
            : index.display_label
        }}
        <v-rating
          v-model="rating"
          background-color="indigo lighten-3"
          color="primaryColor"
          size="30"
          :rules="index.is_required == true ? [(v) => !!v || 'required '] : []"
          @input="get_value(rating, index.display_label, index.key)"
        ></v-rating>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    index: Object,
    formActionType: String,
  },
  data() {
    return {
      rating: 0,
    };
  },
  created() {
    this.rating = this.index.value;
  },
  methods: {
    emit_fields(Toggle, index) {
      this.$emit("clicked", Toggle, index);
    },
    get_value(fieldvalue, fieldlabel, fieldKey) {
      var data = {
        label: fieldlabel,
        value: fieldvalue,
        key: fieldKey,
      };
      this.$emit("ratingField", data);
    },
    emit_edit_field(value) {
      this.$emit("ratingEdit", value);
    },
  },
};
</script>