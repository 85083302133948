<template>
  <div>
    <v-card v-if="formActionType != 'initiate'" flat class="mt-2 mb-4 ">
      <v-card-text>
        <v-row>
          <v-col cols="11">
            <div @click="emit_edit_field(index)" class="text-left">
              {{ index.displayLabel }}
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              v-if="
                formActionType != 'initiate' &&
                formActionType != 'preview' &&
                formActionType != 'previewproof'
              "
              x-small
              color="red"
              fab
              class=""
              dark
              
              right
              @click="emit_fields((Toggle = 1), index)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat v-else>
      <div class="text-left">{{ index.displayLabel }}</div>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    index: Object,
    formActionType: String,
    formAction: String,
    formTemplate: Array,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    emit_fields(Toggle, index) {
      this.$emit("clicked", Toggle, index);
    },
    emit_edit_field(value) {
      this.$emit("labelEdit", value);
    },
  },
};
</script>