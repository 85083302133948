<template>
  <div>
    <v-dialog
      :height="height"
      class="overflow-y-hidden"
      v-model="viewOrgDialog"
      persistent
      fullscreen
    >
      <v-toolbar class="gradient-bg white--text" elevation="0" dense>
        <v-toolbar-title class="mr-2">
          Viewing Details -
          {{ currentOrgObject.organisation_name }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn @click="close_dialog()" icon dark>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-tabs background-color="grey lighten-3" v-model="tab" grow>
          <v-tab>Daily Scans</v-tab>
          <v-tab>Organisation Details</v-tab>
          <v-tab
            >Locations
            <v-badge
              color="#519259"
              :content="currentOrgObject.organisation_total_locations"
              inline
              offset-y="-1"
            />
          </v-tab>
          <v-tab
            >Members
            <v-badge
              color="#519259"
              :content="currentOrgObject.organisation_total_users"
              inline
              offset-y="-1"
            />
          </v-tab>
          <v-tab
            >Admins
            <v-badge
              color="#519259"
              :content="currentOrgObject.organisation_total_admin"
              inline
              offset-y="-1"
            />
          </v-tab>
          <v-tab>Transactions</v-tab>
          <v-tab>Scan Logs</v-tab>
          <v-tab>Forms</v-tab>
          <v-tab>Comments</v-tab>
        </v-tabs>
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              v-model="OrgNameStatus"
              :items="AllOrgObject"
              dense
              outlined
              class="mt-4 ml-3"
              label="Organization Name"
              item-text="organisation_name"
              item-value="organisation_name"
            ></v-select>
          </v-col>
          <v-col cols="12" md="2" v-if="tab == 0">
            <v-select
              dense
              outlined
              label="Filter By"
              v-model="filterCredits"
              :items="filterCreditsItems"
              class="mt-4 ml-n3"
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              label="Search"
              class="mt-6 ml-n3"
              v-model="search"
              append-icon="mdi-magnify"
              style="max-width: 220px"
              dense
              v-if="tab == 3"
            />
          </v-col>
        </v-row>
        <v-card class="elevation-0 mt-n9 mx-2" v-if="tab == 6">
          <ScanLogs v-if="renderScanLogs" :OrgObj="currentOrgObject" />
        </v-card>
        <v-card class="elevation-0 mt-n9 mx-2" v-if="tab == 7">
          <FormsList v-if="renderForms" :OrgObj="currentOrgObject" />
        </v-card>
        <v-card class="elevation-0 mt-n9 mx-2" v-if="tab == 8">
          <CommentsList v-if="renderComments" :OrgObj="currentOrgObject" />
        </v-card>
        <v-card class="elevation-0 mt-n9 mx-2" v-if="tab == 5">
          <Transactions v-if="renderTransactions" :OrgObj="currentOrgObject" />
        </v-card>
        <v-card class="elevation-0 mt-n9 mx-2" v-if="tab == 4">
          <Admins v-if="renderAdmins" :OrgObj="currentOrgObject" />
        </v-card>
        <v-card class="elevation-0 mt-n9 mx-2" v-if="tab == 3">
          <Members
            v-if="renderMembers"
            :OrgObj="currentOrgObject"
            :watchsearch="search"
          />
        </v-card>
        <v-card class="elevation-0 mt-n9 mx-2" v-if="tab == 2">
          <Locations v-if="renderLocations" :OrgObj="currentOrgObject" />
        </v-card>
        <v-card class="elevation-0 mt-n9" v-if="tab == 1">
          <OrganisationDetails
            v-if="renderComponent2"
            @currentView="getCurrentView"
            @close_org_dialog_open_again="close_open_org_dialog"
            @re_render="close_open_org_dialog"
            :type="type"
            :OrgObject="currentOrgObject"
            :tab="tab"
            :openView="openView"
            :firstMount="firstMount"
          />
        </v-card>
        <v-card class="elevation-0" v-if="tab == 0">
          <DailyScans
            v-if="renderComponent"
            :tab="tab"
            :OrgObject="currentOrgObject"
            :openView="openView2"
            @currentView="getCurrentView2"
            :watchCredits="filterCredits"
          />
        </v-card>
      </v-card>
    </v-dialog>
    <!-- <Customer :fromViewOrg="customerView" /> -->
  </div>
</template>

<script>
import CommentsList from "@/components/Dialogs/Organisations/CommentsList.vue"
import FormsList from "@/components/Dialogs/Organisations/FormsList.vue";
import OrganisationDetails from "@/components/Dialogs/Organisations/OrganisationDetails.vue";
import DailyScans from "@/components/Dialogs/Organisations/DailyScans.vue";
import Locations from "@/components/Dialogs/Organisations/Locations.vue";
import Members from "@/components/Dialogs/Organisations/Members.vue";
import Admins from "@/components/Dialogs/Organisations/Admins.vue";
import Transactions from "@/components/Dialogs/Organisations/Transactions.vue";
import ScanLogs from "@/components/Dialogs/Organisations/ScanLogs.vue";

// import Customer from "@/components/Cards/Organisations/Customer.vue";

export default {
  props: {
    from_change_ref: Boolean,
    viewOrgDialog: Boolean,
    OrgObject: Object,
    AllOrgObject: Array,
    type: String,
  },
  components: {
    OrganisationDetails,
    DailyScans,
    Locations,
    Members,
    Admins,
    Transactions,
    ScanLogs,
    FormsList,
    CommentsList,
    // Customer,
  },
  data: () => ({
    firstMount: false,
    prospectView: false,
    search: "",
    openView: "",
    openView2: "",
    tab: null,
    OrgNameStatus: "",
    PrevOrgNameStatus: "",
    currentOrgObject: {},
    renderComponent: true,
    renderComponent2: true,
    filterCredits: "count_credits",
    filterCreditsItems: [
      { text: "COUNT CREDITS", value: "count_credits" },
      { text: "AVERAGE CREDITS", value: "avg_credits" },
      { text: "MONTHLY CREDITS", value: "monthly_credits" },
    ],
    renderLocations: true,
    renderMembers: true,
    renderAdmins: true,
    renderTransactions: true,
    renderScanLogs: true,
    renderForms: true,
    renderComments:true,
    forScanLog: false,
    height: 0,
    organisation_credits: "",
    organisation_status_items: [
      { text: "CUSTOMERS", value: "CUSTOMER" },
      { text: "PROSPECTS", value: "PROSPECT" },
      { text: "ACTIVATED", value: "ACTIVATED" },
      { text: "ONBOARDED", value: "ONBOARD" },
    ],
  }),
  watch: {
    viewOrgDialog(val) {
      if (val == true) {
        this.height = window.innerHeight;
        this.type = "";
        if (this.from_change_ref == true) {
          this.OrgObject = this.AllOrgObject.filter(
            (val) =>
              val.organisation_id == this.currentOrgObject.organisation_id
          )[0];
          this.tab = 1;
        } else {
          this.tab = 0;
          this.firstMount = true;
        }
        this.OrgNameStatus = this.OrgObject.organisation_name;
        this.currentOrgObject = this.OrgObject;
        // console.log("OrgObject", this.OrgObject);
        // console.log("AllOrgObject", this.AllOrgObject);
      }
    },
    OrgNameStatus(val) {
      this.currentOrgObject = this.AllOrgObject.filter(
        (item) => item.organisation_name == val
      )[0];
      if (val) {
        if (this.tab == 1) {
          this.firstMount = false;
          this.forceRerender2();
        } else if (this.tab == 2) {
          this.forceRerenderLocations();
        } else if (this.tab == 3) {
          this.forceRerenderMembers();
        } else if (this.tab == 4) {
          this.forceRerenderAdmins();
        } else if (this.tab == 5) {
          this.forceRerenderTransactions();
        } else if (this.tab == 6) {
          this.forceRerenderScanLogs();
        } else if (this.tab == 7) {
          this.forceRerenderForms();
        } else if(this.tab ==8){
          this.forceRerendercomments();
        }
        else {
          this.forceRerender();
        }
      }
    },
  },
  methods: {
    close_open_org_dialog(val) {
      if (val === "from_change_ref") {
        this.$emit("get_refreshed_orgs2");
      } else {
        this.$emit("get_refreshed_orgs");
      }
    },
    getCurrentView(val) {
      this.openView = val;
    },
    getCurrentView2(val) {
      this.openView2 = val;
    },
    close_dialog() {
      this.filterCredits = "count_credits";
      this.openView2 = "DAILY";
      this.tab = 1;
      this.type = "";
      this.$emit("close_view_org_dialog");
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    forceRerender2() {
      this.renderComponent2 = false;
      this.$nextTick(() => {
        this.renderComponent2 = true;
      });
    },
    forceRerenderLocations() {
      this.renderLocations = false;
      this.$nextTick(() => {
        this.renderLocations = true;
      });
    },
    forceRerenderMembers() {
      this.renderMembers = false;
      this.$nextTick(() => {
        this.renderMembers = true;
      });
    },
    forceRerenderAdmins() {
      this.renderAdmins = false;
      this.$nextTick(() => {
        this.renderAdmins = true;
      });
    },
    forceRerenderTransactions() {
      this.renderTransactions = false;
      this.$nextTick(() => {
        this.renderTransactions = true;
      });
    },
    forceRerenderScanLogs() {
      this.renderScanLogs = false;
      this.$nextTick(() => {
        this.renderScanLogs = true;
      });
    },
    forceRerenderForms() {
      this.renderForms = false;
      this.$nextTick(() => {
        this.renderForms = true;
      });
    },
    forceRerendercomments(){
      this.renderComments = false;
      this.$nextTick(() => {
        this.renderComments = true;
      });
    }
  },
};
</script>
<style></style>
