var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.formActionType != 'initiate')?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dateField,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateField=$event},"update:return-value":function($event){_vm.dateField=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"FontSize",attrs:{"dense":"","label":_vm.index.is_required == true
                    ? _vm.index.displayLabel + '*'
                    : _vm.index.displayLabel,"readonly":"","outlined":""},on:{"click":function($event){return _vm.emit_edit_field(_vm.index)}},model:{value:(_vm.dateField),callback:function ($$v) {_vm.dateField=$$v},expression:"dateField"}},'v-text-field',attrs,false),on))]}}],null,false,218192484),model:{value:(_vm.menuDatePicker),callback:function ($$v) {_vm.menuDatePicker=$$v},expression:"menuDatePicker"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.dateField),callback:function ($$v) {_vm.dateField=$$v},expression:"dateField"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dateField)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"1"}},[(
              _vm.formActionType != 'initiate' &&
              _vm.formActionType != 'preview' &&
              _vm.formActionType != 'previewproof'
            )?_c('v-btn',{attrs:{"x-small":"","color":"red","fab":"","dark":"","right":""},on:{"click":function($event){_vm.emit_fields((_vm.Toggle = 1), _vm.index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)],1)],1):_c('v-card',{attrs:{"flat":""}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dateField,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateField=$event},"update:return-value":function($event){_vm.dateField=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"FontSize",attrs:{"dense":"","label":_vm.index.is_required == true
              ? _vm.index.display_label + '*'
              : _vm.index.display_label,"readonly":"","rules":_vm.index.is_required == true ? [function (v) { return !!v || 'required '; }] : [],"outlined":""},model:{value:(_vm.dateField),callback:function ($$v) {_vm.dateField=$$v},expression:"dateField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDatePicker),callback:function ($$v) {_vm.menuDatePicker=$$v},expression:"menuDatePicker"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.dateField),callback:function ($$v) {_vm.dateField=$$v},expression:"dateField"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.get_save_value(_vm.dateField, _vm.index.display_label, _vm.index.key)}}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }