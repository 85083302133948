<template>
  <div>
    <v-dialog v-model="changeRefDialog" max-width="500" persistent>
      <v-card>
        <v-toolbar class="gradient-bg" dense>
          <v-toolbar-title class="white--text">
            Change Referred By
          </v-toolbar-title>
          <v-spacer />
          <v-btn class="white--text" @click="closeDialog()" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card class="overflow-hidden" height="118px">
          <v-form>
            <v-row>
              <v-select
                dense
                outlined
                label="Change Referred By"
                class="mt-7 mx-9"
                :items="SalesRepItems"
                item-text="user_email_id"
                item-value="user_email_id"
                v-model="selectedItem"
                return-object
              ></v-select>
            </v-row>
            <v-row justify="center" class="mt-n3">
              <v-btn
                @click="changeRefBy()"
                class="gradient-bg white--text"
                small
                >Confirm</v-btn
              >
            </v-row>
          </v-form>
        </v-card>
      </v-card>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { ListSalesRep } from "@/graphql/queries.js";
import { AddReferredToOrganisation } from "@/graphql/mutations.js";
import SnackBar from "@/components/SnackBar.vue";

export default {
  components: {
    SnackBar,
  },
  props: {
    changeRefDialog: Boolean,
    refObj: Object,
  },
  data: () => ({
    overlay: false,
    SnackBarComponent: {},
    SalesRepItems: [],
    selectedItem: "",
  }),
  watch: {
    async changeRefDialog(val) {
      if (val == true) {
        this.selectItem = [];
        await this.get_sales_reps_list();
        this.selectedItem = this.refObj.referred_by;
      }
    },
  },
  methods: {
    async get_sales_reps_list() {
      try {
        let result = await API.graphql(
          graphqlOperation(ListSalesRep, {
            input: {
              user_status: "ACTIVE",
            },
          })
        );
        this.SalesRepItems = JSON.parse(result.data.ListSalesRep).Items.sort(
          (a, b) => (a.user_email_id > b.user_email_id ? 1 : -1)
        );
        this.$forceUpdate();
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    closeDialog() {
      this.$emit("close_dialog");
    },
    async changeRefBy() {
      try {
        await await API.graphql(
          graphqlOperation(AddReferredToOrganisation, {
            input: {
              organisation_id: this.refObj.organisation_id,
              rep_id: this.selectedItem.rep_id,
            },
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: JSON.parse(res.data.AddReferredToOrganisation)
              .message,
          };
          this.$emit("re_render");
          this.closeDialog();
        });
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText:
            err.errors[0].message ===
            "ValidationException: ExpressionAttributeValues must not be empty"
              ? "Email-ID Already Tagged!"
              : err.errors[0].message,
        };
      }
    },
  },
};
</script>

<style></style>
