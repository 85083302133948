<template>
  <div>
    <v-card flat v-if="formActionType != 'initiate'">
      <!-- :flat="formActionType != undefined" -->
      <v-card-text>
        <v-row>
          <v-col cols="11">
            <v-select
              dense
              outlined
              class=""
              :label="
                index.is_required == true
                  ? index.displayLabel + '*'
                  : index.displayLabel
              "
              :items="index.fieldOptions"
              @click="emit_edit_field(index)"
            ></v-select>
          </v-col>
          <v-col cols="1">
            <v-btn
              x-small
              color="red"
              v-if="formActionType != 'initiate' && formActionType != 'preview' && formActionType != 'previewproof'"
              fab
              dark
              right
              @click="emit_fields((Toggle = 1), index)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat v-else>
      <v-select
        dense
        outlined
        v-model="dropdownOption"
        class=""
        :label="
          index.is_required == true
            ? index.display_label + '*'
            : index.display_label
        "
        :rules="index.is_required == true ? [(v) => !!v || 'required '] : []"
        :items="index.input_array_value"
        item-text="type"
        item-value="type"
        @blur="get_value(dropdownOption, index.display_label, index.key)"
      ></v-select>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    index: Object,
    formActionType: String,
  },
  data: () => ({
    Toggle: 0,
    dropdownOption: "",
  }),
  created() {},
  methods: {
    emit_fields(Toggle, index) {
      this.$emit("clicked", Toggle, index);
    },

    get_value(fieldvalue, fieldlabel, fieldKey) {
      var data = {
        label: fieldlabel,
        value: fieldvalue == "" ? "N/A" : fieldvalue,
        key: fieldKey,
      };
      this.$emit("dropDownField", data);
    },
    emit_edit_field(value) {
      this.$emit("editField", value);
    },
  },
};
</script>
