var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12","xl":"12","xs":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.TransactionHeaders,"items":_vm.transactionItems,"dense":"","items-per-page":15,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v("No Data Available")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.created_on",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.created_on))}})]}},{key:"item.comment_message",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(item.comment_message.length >= 25 ? item.comment_message.substr(0, 25) + "..." : item.comment_message)+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.comment_message))])])]}},{key:"item.attachments_supports",fn:function(ref){
var item = ref.item;
return [(item.attachments_supports == 'CAMERA_ONLY')?_c('span',[_vm._v(" Camera")]):(item.attachments_supports == 'GALLERY_ONLY')?_c('span',[_vm._v(" Gallery")]):_c('span',[_vm._v("Camera & Gallery")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }