<template>
  <v-dialog v-model="creditsdialog" width="800px" persistent>
    <v-card class="overflow-hidden">
      <v-toolbar class="gradient-bg" dense>
        <v-toolbar-title class="white--text"
          >Add Credits to - {{ Org_Name }}</v-toolbar-title
        >
        <v-spacer />
        <v-btn class="white--text" @click="closeDialog()" text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="form">
        <v-card-text>
          <v-row class="mt-3">
            <v-col cols="12" md="6" sm="6" lg="6" xl="6" xs="12">
              <v-select
                v-model="CreditsToBeAdded"
                :rules="creditrules"
                label="Select Credits To Be Added *"
                dense
                item-text="text"
                item-value="value"
                outlined
                :items="CreditsToBeAddedItems"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6" lg="6" xl="6" xs="12">
              <v-select
                v-model="TransactionType"
                :rules="transactionrules"
                :items="TransactionTypeItems"
                label="Transaction Type*"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col
              class="mt-n6"
              v-if="TransactionType == 'CASH'"
              cols="12"
              md="12"
              sm="12"
              lg="12"
              xl="12"
              xs="12"
            >
              <v-text-field
                min="0"
                v-model="AmountCASH"
                :rules="Cashrules"
                label="Enter Amount Recieved *"
                type="number"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              class="mt-n6"
              v-if="TransactionType == 'ONLINE'"
              cols="12"
              md="4"
              sm="4"
              lg="4"
              xl="4"
              xs="12"
            >
              <v-text-field
                min="0"
                v-model="Amount"
                label="Enter Amount Recieved *"
                type="number"
                :rules="Onlinerules"
                hint="Exclude without GST"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              class="mt-n6"
              v-if="TransactionType == 'ONLINE'"
              cols="12"
              md="4"
              sm="4"
              lg="4"
              xl="4"
              xs="12"
            >
              <v-select
                v-model="Mode"
                label="Mode of Payment *"
                :items="ModeItems"
                dense
                :rules="Onlinerules"
                outlined
              ></v-select>
            </v-col>
            <v-col
              class="mt-n6"
              v-if="TransactionType == 'ONLINE'"
              cols="12"
              md="4"
              sm="4"
              lg="4"
              xl="4"
              xs="12"
            >
              <v-text-field
                v-model="RefID"
                :rules="Onlinerules"
                :label="
                  Mode == 'UPI'
                    ? 'Enter Reference Number *'
                    : 'Enter Cheque Number *'
                "
                dense
                outlined
              ></v-text-field>
            </v-col>
            <!-- <v-col
              class="mt-n6"
              v-if="TransactionType == 'ONLINE' && Mode == 'CHEQUE'"
              cols="12"
              md="4"
              sm="4"
              lg="4"
              xl="4"
              xs="12"
            >
              <v-text-field
                v-model="ChequeNumber"
                label="Enter Cheque Number *"
                dense
                outlined
              ></v-text-field>
            </v-col> -->
            <v-col
              class="mt-n6"
              cols="12"
              md="12"
              sm="12"
              lg="12"
              xl="12"
              xs="12"
            >
              <v-textarea
                :label="
                  TransactionType == 'PROMOTIONAL'
                    ? 'Comments *'
                    : 'Comments (if any)'
                "
                v-model="Comments"
                dense
                outlined
                rows="1"
                :rules="Promrules"
                auto-grow
              ></v-textarea>
            </v-col>
            <v-card-text class="mt-n6" align="center">
              <v-btn
                :loading="isLoading"
                @click="validateCreditMethod()"
                class="gradient-bg white--text"
                >Add Credits</v-btn
              >
            </v-card-text>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { AddCreditsOrganisation } from "@/graphql/mutations.js";

export default {
  props: {
    creditsdialog: Boolean,
    CreditsObject: Object,
  },
  data: () => ({
    isLoading: false,
    creditrules: [(v) => !!v || "Credit is required"],
    Onlinerules: [],
    Cashrules: [],
    Promrules: [],
    transactionrules: [(v) => !!v || "Transaction Type is required"],
    namerules: [
      (v) => !!v || "Organization Name is required",
      (v) =>
        /^[a-z\sA-Z 0-9]{3,30}$/.test(v) ||
        "Name must be between 3 & 30 characters",
    ],
    CreditsToBeAdded: "",
    CreditsToBeAddedItems: [
      { text: "50 CREDITS", value: 50 },
      { text: "500 CREDITS", value: 500 },
      { text: "2500 CREDITS", value: 2500 },
      { text: "5000 CREDITS", value: 5000 },
      { text: "10000 CREDITS", value: 10000 },
      { text: "25000 CREDITS", value: 25000 },
      { text: "50000 CREDITS", value: 50000 },
      { text: "100000 CREDITS", value: 100000 },
    ],
    TransactionType: "",
    TransactionTypeItems: ["PROMOTIONAL", "ONLINE", "CASH"],
    Amount: 0,
    Mode: "UPI",
    ModeItems: ["UPI", "CHEQUE"],
    ChequeNumber: "",
    RefID: "",
    AmountCASH: 0,
    Comments: "",
    Org_Name: "",
    EmailID: "",
    Organisation_Id: "",
  }),
  watch: {
    creditsdialog(val) {
      if (val == true) {
        this.TransactionType = "PROMOTIONAL";
        this.Org_Name = this.CreditsObject.organisation_name;
        this.Organisation_Id = this.CreditsObject.organisation_id;
        this.EmailID = this.CreditsObject.user_email_id;
      }
    },
    TransactionType(val) {
      this.$refs.form.resetValidation();
      if (val == "ONLINE") {
        this.Onlinerules = [(v) => !!v || "Field(s) required"];
      } else if (val == "CASH") {
        this.Cashrules = [(v) => !!v || "Field(s) required"];
      } else if (val == "PROMOTIONAL") {
        this.Promrules = [(v) => !!v || "Field(s) required"];
      }
    },
  },
  methods: {
    validateCreditMethod() {
      if (this.$refs.form.validate()) {
        this.addCreditsMethod();
      } else {
        this.$store.commit("snackbar/showMessage", {
          content: "Field(s) marked * is required.",
          color: "red",
        });
      }
    },
    async addCreditsMethod() {
      this.isLoading = true;
      try {
        var inputParams = {
          credits: this.CreditsToBeAdded,
          organisation_id: this.Organisation_Id,
          user_email_id: this.EmailID,
          order_id: this.RefID,
          pack_name: this.TransactionType,
          mode: this.Mode,
          amount:
            this.TransactionType == "CASH"
              ? Number(this.AmountCASH)
              : this.TransactionType == "ONLINE"
              ? Number(this.Amount)
              : 0,
          comment: this.Comments,
        };
        await API.graphql(
          graphqlOperation(AddCreditsOrganisation, {
            input: inputParams,
          })
        ).then((res) => {
          this.$store.commit("snackbar/showMessage", {
            content: JSON.parse(res.data.AddCreditsOrganisation).message,
            color: "green",
          });
          if (this.$route.name == "Credits") {
            this.closeDialog();
            this.$emit("getCredits");
          } else {
            this.$emit("close_get_org");
          }
        });
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.$store.commit("snackbar/showMessage", {
          content: "Unable to Add Credits. Contact Administrator.",
          color: "red",
        });
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.$emit("closedialog");
      this.$refs.form.reset();
    },
  },
};
</script>

<style>
</style>