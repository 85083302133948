<template>
  <div>
    <v-dialog v-model="viewSourceDialog" persistent width="700px">
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text"
          >View Sources for Location -
          {{ locationObject.location_name }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn @click="close_dialog()" text>
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-row class="mx-3" no-gutters>
          <v-col cols="12" md="12" sm="12" lg="12" xl="12">
            <v-data-table
              :headers="viewSourceData"
              :items="viewSourceItems"
              :loading="isLoading"
              loading-text="Loading... Please wait"
              dense
              class="overflow"
              :items-per-page="5"
            >
              <template v-slot:[`item.qr_location_name`]="{ item }">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div class="overflow" v-on="on">
                      {{ item.qr_location_name }}
                    </div>
                  </template>
                  <span class="white--text">{{ item.qr_location_name }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`no-data`]>
                <v-alert class="gradient-bg2 white--text"
                  >No Source(s) available for this Location
                </v-alert>
              </template>
              <template v-slot:[`item.view_qr`]="{ item }">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <a @click="view_qr(item)">
                      <v-icon v-on="on" small class="primary--text mr-1"
                        >mdi-qrcode
                      </v-icon>
                    </a>
                  </template>
                  <span class="white--text">View QR</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <Overlay :overlay="overlay" />
    <ViewMemberQR
      :viewMemberQRDialog="viewMemberQRDialog"
      @close_view_qr_dialog="viewMemberQRDialog = false"
      :userDetailsObj="userDetailsObj"
    />
  </div>
</template>

<script src="vue.min.js"></script>
<script src="dist/vue-clipboard.min.js"></script>
<script>
import { API, graphqlOperation } from "aws-amplify";
import { GetSubLocations } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import ViewMemberQR from "@/components/Dialogs/Organisations/OrganisationDetails/ViewMemberQR.vue";

export default {
  components: {
    Overlay,
    ViewMemberQR,
  },
  props: {
    viewSourceDialog: Boolean,
    locationObject: Object,
  },
  data: () => ({
    viewMemberQRDialog: false,
    userDetailsObj: {},
    overlay: false,
    addSourceLocationDialog: false,
    isLoading: false,
    SourceObject: {},
    viewSourceData: [
      { text: "Source Name", value: "qr_location_name" },
      { text: "Direction", value: "location_direction" },
      { text: "View QR", value: "view_qr" },
    ],
    viewSourceItems: [],
  }),
  watch: {
    viewSourceDialog(val) {
      if (val == true) {
        this.list_sources_locations();
      }
    },
  },
  methods: {
    view_qr(item) {
      this.userDetailsObj = item;
      this.viewMemberQRDialog = true;
    },
    async list_sources_locations() {
      this.overlay = true;
      this.isLoading = true;
      try {
        await API.graphql(
          graphqlOperation(GetSubLocations, {
            input: {
              location_id: this.locationObject.location_id,
            },
          })
        ).then((res) => {
          this.viewSourceItems = JSON.parse(res.data.GetSubLocations);
          this.overlay = false;
          this.isLoading = false;
        });
      } catch (err) {
        console.log("err", err);
        this.overlay = false;
        this.isLoading = false;
      }
    },
    close_dialog() {
      this.$emit("close_view_src_dialog");
    },
  },
};
</script>

<style></style>
