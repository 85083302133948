import { Pie } from "vue-chartjs";
export default {
  extends: Pie,
  props: {
    data: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    count: Number,
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
  watch: {
    count(val) {
      if (val == 1) {
        this.renderChart(this.data, this.options);
      }
    },
  },
};
