<template>
  <div>
    <v-dialog v-model="referralSub" max-width="600" persistent>
      <v-card flat>
        <v-toolbar class="gradient-bg" dense>
          <v-toolbar-title class="white--text">
            Change Sub Referred By
          </v-toolbar-title>
          <v-spacer />
          <v-btn class="white--text" @click="closeDialog()" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-toolbar flat
          ><v-spacer /><v-btn
            small
            class="gradient-bg white--text"
            @click="add_members()"
            >Add</v-btn
          ></v-toolbar
        >

        <v-card-text>
          <v-data-table
            :headers="organisationData"
            :items="organisationItems"
            dense
            class="overflow-y-auto overflow"
            :fixed-header="fixed"
            :height="height"
            :search="search"
            :items-per-page="100"
            :loading="isLoading"
          >
            <template v-slot:[`item.comments`]="{ item }">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <div v-if="item.comments" class="caption" v-on="on">
                    {{
                      item.comments.length >= 10
                        ? item.comments.substr(0, 10) + "..."
                        : item.comments
                    }}
                  </div>
                  <div v-else>-</div>
                </template>
                <div class="textWrap white--text" style="max-width: 250px">
                  {{ item.comments }}
                </div>
              </v-tooltip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="open_delete(item)" color="red"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>
          <!-- <v-form>
            <v-row>
              <v-select
                dense
                outlined
                label="Change Referred By"
                class="mt-7 mx-9"
                :items="SalesRepItems"
                item-text="user_email_id"
                item-value="user_email_id"
                v-model="selectedItem"
                return-object
              ></v-select>
            </v-row>
            <v-row justify="center" class="mt-n3">
              <v-btn
                @click="changeRefBy()"
                class="gradient-bg white--text"
                small
                >Confirm</v-btn
              >
            </v-row>
          </v-form> -->
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            @click="changeRefBy()"
            class="gradient-bg white--text mt-n5 mb-3"
            small
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
    </v-dialog>
    <v-dialog v-model="addsubemail" max-width="400" persistent>
      <v-card flat>
        <v-toolbar class="gradient-bg" dense>
          <v-toolbar-title class="white--text">
            Add Subrefferals
          </v-toolbar-title>
          <v-spacer />
          <v-btn class="white--text" @click="closeaddDialog()" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-select
              dense
              outlined
              label="Change Referred By"
              class="mt-4 mx-2"
              :items="SalesRepItems"
              item-text="user_email_id"
              item-value="user_email_id"
              v-model="selectedItemaddNew"
              :rules="[rules.required]"
              return-object
            ></v-select>
            <v-textarea
              v-model="comments"
              outlined
              dense
              :rules="[rules.required]"
              label="Comments"
              class="mx-2"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            @click="validateMember()"
            class="gradient-bg white--text mt-n5 mb-3"
            small
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="leavedeletion" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Delete Leave type</v-toolbar-title><v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-5">
            Would you like to Delete {{ deleteObject.email_id }} ?
          </div>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_dialog()"
              small
              >No</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="delet_leave(deleteObject)"
              small
              >Yes</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { API, graphqlOperation } from "aws-amplify";
import { ListSalesRep } from "@/graphql/queries.js";
import { add_sub_referrer } from "@/graphql/mutations.js";
import SnackBar from "@/components/SnackBar.vue";

export default {
  components: {
    SnackBar,
  },
  props: {
    referralSub: Boolean,
    refObj: Object,
  },
  data: () => ({
    overlay: false,
    SnackBarComponent: {},
    addsubemail: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    SalesRepItems: [],
    search: "",
    height: 0,
    leavedeletion: false,
    selectedItem: "",
    isLoading: false,
    organisationItems: [],
    comments: "",
    selectedItemaddNew: "",
    deleteObject: {},
    fixed: true,
    organisationData: [
      {
        text: "Email ID",
        value: "refered_by_email",

        sortable: false,
      },
      {
        text: "Comments",
        value: "comments",

        sortable: false,
      },
      { text: "Action", value: "actions", sortable: false },
    ],
  }),
  watch: {
    referralSub: {
      async handler() {
        {
          this.height = window.innerHeight - 380;
          this.organisationItems = [];
          await this.get_sales_reps_list();

          //
          this.organisationItems = this.refObj.sub_referrers;
          // console.log( this.refObj.sub_referrers);
          // console.log(this.organisationItems);
        }
      },
      immediate: true,
    },
  },
  //   watch: {
  //     async referralSub(val) {
  //       if (val == true) {
  //         this.height = window.innerHeight;
  //         this.selectItem = [];
  //         await this.get_sales_reps_list();
  //         this.selectedItem = this.refObj.referred_by;
  //       }
  //     },
  //   },
  methods: {
    delet_leave(item) {
      for (let i = 0; i < this.organisationItems.length; i++) {
        if (
          item.refered_by_email == this.organisationItems[i].refered_by_email
        ) {
          this.organisationItems.splice(i, 1);
        }
      }
      this.leavedeletion = false;
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "User is Unmapped",
      };
    },
    close_dialog() {
      this.leavedeletion = false;
    },
    open_delete(item) {
      this.deleteObject = item;
      this.leavedeletion = true;
    },
    validateMember() {
      if (this.$refs.form.validate()) {
        var key_object = {
          comments: this.comments,
          refered_by_email: this.selectedItemaddNew.user_email_id,
          refered_by_name: this.selectedItemaddNew.refered_by_name,
        };

        var hasMatchingCode = this.organisationItems.some(
          (obj) =>
            obj.refered_by_email === this.selectedItemaddNew.user_email_id
        );
        if (hasMatchingCode == false) {
          this.organisationItems.push(key_object);
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Email Id  already Added",
          };
        }
        // var data = {
        //   comments: this.comments,
        //   email_id: this.selectedItemaddNew,
        // };
        // this.organisationItems.push(data);
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.addsubemail = false;
      }
    },
    add_members() {
      this.addsubemail = true;
    },
    closeaddDialog() {
      this.addsubemail = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    async get_sales_reps_list() {
      try {
        let result = await API.graphql(
          graphqlOperation(ListSalesRep, {
            input: {
              user_status: "ACTIVE",
            },
          })
        );
        var response = JSON.parse(result.data.ListSalesRep).Items;
        console.log(response);
        response.forEach((element) => {
          if (element.user_status == "ACTIVE") {
            this.SalesRepItems.push({
              user_email_id: element.user_email_id,
              refered_by_name: element.user_name,
            });
          }
        });
        this.SalesRepItems.sort((a, b) =>
          a.user_email_id > b.user_email_id ? 1 : -1
        );
        // this.SalesRepItems = JSON.parse(result.data.ListSalesRep).Items.sort(
        //   (a, b) => (a.user_email_id > b.user_email_id ? 1 : -1)
        // );
        // console.log(this.SalesRepItems);
        this.$forceUpdate();
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    closeDialog() {
      this.$emit("close_dialog");
    },
    async changeRefBy() {
      try {
        await await API.graphql(
          graphqlOperation(add_sub_referrer, {
            input: {
              organisation_id: this.refObj.organisation_id,
              sub_referrers: this.organisationItems,
            },
          })
        ).then((res) => {
          console.log(res);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: JSON.parse(res.data.add_sub_referrer).message,
          };
          this.$emit("re_render");
          this.closeDialog();
        });
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText:
            err.errors[0].message ===
            "ValidationException: ExpressionAttributeValues must not be empty"
              ? "Email-ID Already Tagged!"
              : err.errors[0].message,
        };
      }
    },
  },
};
</script>
  
  <style></style>
  