<template>
  <div>
    <!-- eslint-disable -->
    <v-card flat v-if="formActionType != 'initiate'">
      <!-- :flat="formActionType != undefined" -->
      <v-card-text>
        <v-row>
          <v-col cols="11">
            <v-menu
              ref="menu"
              v-model="menuDatePicker"
              :close-on-content-click="false"
              :return-value.sync="dateField"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateField"
                  dense
                  class="FontSize"
                  :label="
                    index.is_required == true
                      ? index.displayLabel + '*'
                      : index.displayLabel
                  "
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="emit_edit_field(index)"
                >
                </v-text-field>
              </template>
              <v-date-picker v-model="dateField" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(dateField)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="1">
            <v-btn
              x-small
              color="red"
              v-if="
                formActionType != 'initiate' &&
                formActionType != 'preview' &&
                formActionType != 'previewproof'
              "
              fab
              dark
              right
              @click="emit_fields((Toggle = 1), index)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat v-else>
      <v-menu
        ref="menu"
        v-model="menuDatePicker"
        :close-on-content-click="false"
        :return-value.sync="dateField"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateField"
            dense
            class="FontSize"
            :label="
              index.is_required == true
                ? index.display_label + '*'
                : index.display_label
            "
            readonly
            :rules="
              index.is_required == true ? [(v) => !!v || 'required '] : []
            "
            outlined
            v-bind="attrs"
            v-on="on"
          >
          </v-text-field>
        </template>
        <v-date-picker v-model="dateField" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn
            text
            color="primary"
            @click="get_save_value(dateField, index.display_label, index.key)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    index: Object,
    formActionType: String,
  },
  data: () => ({
    Toggle: 0,
    menuDatePicker: false,
    dateField: "",
  }),
  created() {
    this.dateField = this.index.value;
  },
  methods: {
    emit_fields(Toggle, index) {
      this.$emit("clicked", Toggle, index);
    },

    get_save_value(dateField, fieldlabel, fieldKey) {
      this.$refs.menu.save(dateField);
      this.get_value(dateField, fieldlabel, fieldKey);
    },
    emit_edit_field(value) {
      this.$emit("editField", value);
    },
    get_value(fieldvalue, fieldlabel, fieldKey) {
      var data = {
        label: fieldlabel,
        value: fieldvalue == "" ? "N/A" : fieldvalue,
        key: fieldKey,
      };

      this.$emit("dateField", data);
    },
  },
};
</script>
