<template>
  <div>
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
        <v-data-table
          :headers="TransactionHeaders"
          :items="transactionItems"
          dense
          :items-per-page="15"
          :loading="isLoading"
          @click:row="handleClick"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text"
              >No Data Available</v-alert
            >
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.form_created_on`]="{ item }">
            <span v-text="get_date(item.form_created_on)"></span>
          </template>
          <template v-slot:[`item.attachments_supports`]="{ item }">
            <span v-if="item.attachments_supports == 'CAMERA_ONLY'">
              Camera</span
            >
            <span v-else-if="item.attachments_supports == 'GALLERY_ONLY'">
              Gallery</span
            >
            <span v-else>Camera & Gallery</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div v-if="componentCheck == 4">
      <FormPreview
        :previewDialog="previewDialog"
        :formFields="formFields"
        @clicked="previewDialog = false"
      />
    </div>
  </div>
</template>
  
  <script>
import { API, graphqlOperation } from "aws-amplify";
import { list_forms } from "@/graphql/queries.js";
import FormPreview from "@/components/Dialogs/FormPreview.vue";
export default {
  props: {
    OrgObj: Object,
  },
  components: {
    FormPreview,
  },
  data: () => ({
    overlay: false,
    isLoading: false,
    componentCheck: 0,
    previewDialog: false,
    formFields: [],
    transactionItems: [],
    TransactionHeaders: [
      {
        text: "Form Name",
        value: "form_name",
      },
      {
        text: "Form Type",
        value: "work_type",
      },
      {
          text: "Created On",
          value: "form_created_on",
          fixed: true,
          sortable: false,
        },
        {
          text: "Attachments",
          value: "attachments_supports",
          fixed: true,
          sortable: false,
        },
    ],
  }),
  mounted() {
    this.viewTransactions(this.OrgObj);
  },
  methods: {
    get_date(date) {
      // console.log(date, "data");
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      // console.log(a);
      return a.toUpperCase();
    },
    handleClick(value) {
      this.componentCheck = 4;
      this.previewDialog = true;
      this.formFields = value.form_desgin;
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    async viewTransactions(item) {
      this.transactionItems = [];
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(list_forms, {
            input: {
              organisation_id: item.organisation_id,
              nextToken: null,
              limit: 100,
            },
          })
        );
        this.transactionItems = JSON.parse(result.data.list_forms).data;
        // console.log(this.transactionItems);
        this.overlay = false;
        this.isLoading = false;
      } catch (err) {
        this.transactionItems = [];
        this.overlay = false;
        this.isLoading = false;
        console.log(err);
      }
    },
  },
};
</script>
  <style></style>
  