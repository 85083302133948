<template>
  <div>
    <v-card flat v-if="formActionType != 'initiate'">
      <!-- :flat="formActionType != undefined" -->
      <v-card-text>
        <v-row>
          <v-col cols="11">
            <v-text-field
              dense
              outlined
              v-model="numberInput"
              class="FontSize"
              readonly
              type="number"
              :label="
                index.is_required == true
                  ? index.displayLabel + '*'
                  : index.displayLabel
              "
              @click="emit_edit_field(index)"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn
              x-small
              v-if="
                formActionType != 'initiate' &&
                formActionType != 'preview' &&
                formActionType != 'previewproof'
              "
              color="red"
              fab
              dark
              right
              @click="emit_fields((Toggle = 1), index)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat v-else>
      <v-text-field
        dense
        outlined
        class="FontSize"
        v-model="numberInput"
        :label="
          index.is_required == true
            ? index.display_label + '*'
            : index.display_label
        "
        v-on:keypress="is_Number($event)"
        v-on:paste="paste_event($event)"
        :rules="index.is_required == true ? [(v) => !!v || 'required '] : []"
        @blur="get_value(numberInput, index.display_label, index.key)"
      ></v-text-field>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    index: Object,
    formActionType: String,
  },
  data: () => ({
    Toggle: 0,
    numberInput: null,
  }),
  created() {
    this.numberInput = this.index.value;
  },
  methods: {
    emit_fields(Toggle, index) {
      this.$emit("clicked", Toggle, index);
    },
    get_value(fieldvalue, fieldlabel, fieldKey) {
      var data = {
        label: fieldlabel,
        value: fieldvalue == null ? "N/A" : fieldvalue,
        key: fieldKey,
      };
      this.$emit("numberFieldData", data);
    },
    is_Number(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    emit_edit_field(value) {
      this.$emit("editField", value);
    },
    paste_event(event) {
      if (event.type == "paste") {
        var clipboardData = event.clipboardData || window.clipboardData;
        var pastedData = clipboardData.getData("Text");
        if (isNaN(pastedData)) {
          event.preventDefault();
        } else {
          return;
        }
      }
      var keyCode = event.keyCode || event.which;
      if (keyCode >= 96 && keyCode <= 105) {
        keyCode -= 48;
      }
      var charValue = String.fromCharCode(keyCode);
      if (isNaN(parseInt(charValue)) && event.keyCode != 8) {
        event.preventDefault();
      }
    },
  },
};
</script>
