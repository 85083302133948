<template>
  <v-row>
    <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
      <v-data-table
        :headers="adminHeaders"
        :items="adminItems"
        dense
        :items-per-page="15"
        :loading="isLoading"
      >
        <template
          v-for="(h, i) in adminHeaders"
          v-slot:[`header.${h.value}`]="{ header }"
        >
          <div :key="i">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ header.text }}</span>
              </template>
              <span
                class="white--text"
                v-if="header.tooltip_text != undefined"
                >{{ header.tooltip_text }}</span
              >
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`no-data`]>
          <v-alert class="gradient-bg2 white--text">No Data Available</v-alert>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" class="gradient-bg2 white--text">
            No records found!
          </v-alert>
        </template>
        <template v-slot:[`item.admin_app_last_login`]="{ item }">
          <span v-if="item.admin_app_last_login != undefined">{{
            get_date2(item.admin_app_last_login)
          }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:[`item.user_created_on`]="{ item }">
          <span>{{ get_date(item.user_created_on) }}</span>
        </template>
        <template v-slot:[`item.admin_device_type`]="{ item }">
          <v-icon
            class="ml-n2 mr-2"
            medium
            :color="item.admin_device_type == 'ANDROID' ? '#a4c639' : '#555555'"
            v-text="
              item.admin_device_type == 'ANDROID' ? 'mdi-android' : 'mdi-apple'
            "
          ></v-icon>
          <span>{{ item.app_version }} </span>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { GetAllAdminsOfPortal } from "@/graphql/queries.js";

export default {
  props: {
    OrgObj: Object,
  },
  data: () => ({
    overlay: false,
    isLoading: false,
    adminItems: [],
    adminHeaders: [
      {
        text: "Name",
        value: "user_name",
        sortable: false,
      },
      {
        text: "E-Mail",
        value: "user_email_id",
        sortable: false,
      },
      {
        text: "Created On",
        value: "user_created_on",
        sortable: false,
      },
      {
        text: "Last Login",
        value: "admin_app_last_login",
        sortable: false,
      },

      {
        text: "DT / AV",
        value: "admin_device_type",
        tooltip_text: "Device Type / App Version",
        sortable: false,
      },
    ],
  }),
  mounted() {
    this.get_admins(this.OrgObj);
  },
  methods: {
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    get_date2(date) {
      let a = new Date(date).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    async get_admins(item) {
      this.adminItems = [];
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GetAllAdminsOfPortal, {
            input: {
              organisation_id: item.organisation_id,
            },
          })
        );
        this.adminItems = JSON.parse(
          result.data.GetAllAdminsOfPortal
        ).data.items;
        // console.log(this.adminItems);
        this.isLoading = false;
        this.overlay = false;
        this.$forceUpdate();
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
  },
};
</script>
<style></style>
