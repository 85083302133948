<template>
  <div>
    <v-card :flat="formActionType != undefined">
      <v-card-text class="mb-4 mt-n5">
        <v-divider :class="{ 'dark-background': isDark, 'light-background': !isDark }"></v-divider>
        <v-btn
          v-if="formActionType != 'initiate' && formActionType != 'preview'"
          x-small
          color="red"
          fab
          dark
          absolute
          bottom
          right
          @click="emit_fields((Toggle = 1), index)"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    index: Object,
    formActionType: String,
  },
  data() {
    return {
      isDark:true,
    };
  },
  methods: {
    emit_fields(Toggle, index) {
      this.$emit("clicked", Toggle, index);
    },
  },
};
</script>
<style>
.dark-background {
  background-color: #333; /* Replace with your dark background color */
  color: #fff; /* Replace with your light text color */
}

.light-background {
  background-color: #fff; /* Replace with your light background color */
  color: #333; /* Replace with your dark text color */
}
</style>