var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12","xl":"12","xs":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.adminHeaders,"items":_vm.adminItems,"dense":"","items-per-page":15,"loading":_vm.isLoading},scopedSlots:_vm._u([_vm._l((_vm.adminHeaders),function(h,i){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('div',{key:i},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[(header.tooltip_text != undefined)?_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(header.tooltip_text))]):_vm._e()])],1)]}}}),{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v("No Data Available")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.admin_app_last_login",fn:function(ref){
var item = ref.item;
return [(item.admin_app_last_login != undefined)?_c('span',[_vm._v(_vm._s(_vm.get_date2(item.admin_app_last_login)))]):_c('span',[_vm._v("-")])]}},{key:"item.user_created_on",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.get_date(item.user_created_on)))])]}},{key:"item.admin_device_type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-n2 mr-2",attrs:{"medium":"","color":item.admin_device_type == 'ANDROID' ? '#a4c639' : '#555555'},domProps:{"textContent":_vm._s(
            item.admin_device_type == 'ANDROID' ? 'mdi-android' : 'mdi-apple'
          )}}),_c('span',[_vm._v(_vm._s(item.app_version)+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }