<template>
  <div>
    <v-dialog v-model="viewGeoDialog" width="800px" persistent>
      <v-toolbar class="gradient-bg white--text" dense elevation="0">
        <v-toolbar-title v-if="forScanLog == true">
          Geo for Location -- {{ geoObject.user_name }}
        </v-toolbar-title>
        <v-toolbar-title v-if="forScanLog != true">
          Geo for Location -- {{ geoObject.location_name }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn @click="close_dialog()" icon dark>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card elevation="0" class="overflow-hidden">
        <v-card-text class="pa-0 ma-0" v-if="forScanLog == true">
          <span
            class="ml-5 mt-2"
            v-text="
              `Geo Lat: ${geoObject.user_lat}  GeoLong: ${geoObject.user_long}`
            "
          ></span>
        </v-card-text>
        <v-card-text class="pa-0 ma-0" v-if="forScanLog != true">
          <span
            class="ml-5 mt-2"
            v-text="
              `Geo Lat: ${geoObject.geo_lat}  GeoLong: ${geoObject.geo_long}`
            "
          ></span>
        </v-card-text>
        <div class="ma-3">
          <GmapMap
            :center="
              swipeData[0] != undefined ? swipeData[0].position : defaultCenter
            "
            :zoom="18"
            map-type-id="terrain"
            style="width: 100%; height: 500px"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in swipeData"
              :position="m.position"
              :clickable="false"
              :draggable="false"
            />
          </GmapMap>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    viewGeoDialog: Boolean,
    geoObject: Object,
    forScanLog: Boolean,
  },
  data: () => ({
    defaultCenter: {
      lng: 0,
      lat: 0,
    },
    swipeData: [],
  }),
  watch: {
    viewGeoDialog(val) {
      if (val == true) {
        this.swipeData = [];
        if (this.forScanLog == true) {
          this.swipeData.push({
            position: {
              lat: Number(this.geoObject.user_lat),
              lng: Number(this.geoObject.user_long),
            },
          });
        } else {
          this.swipeData.push({
            position: {
              lat: Number(this.geoObject.geo_lat),
              lng: Number(this.geoObject.geo_long),
            },
          });
        }
      }
    },
  },
  methods: {
    close_dialog() {
      this.$emit("close_dialog");
    },
  },
};
</script>

<style></style>
