<template>
  <div>
    <v-card flat v-if="formActionType != 'initiate'">
      <!-- :flat="formActionType != undefined" -->
      <v-card-text>
        <v-row>
          <v-col cols="11">
            <v-switch
              v-model="switch1"
              inset
              dense
              readonly
              :label="
                index.is_required == true
                  ? index.displayLabel + '*'
                  : index.displayLabel
              "
                @click="emit_edit_field(index)"
            ></v-switch>
          </v-col>
          <v-col cols="1">
            <v-btn
              x-small
              v-if="formActionType != 'initiate' && formActionType != 'preview' && formActionType != 'previewproof'"
              color="red"
              fab
              dark
              absolute
              @click="emit_fields((Toggle = 1), index)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat v-else>
      <v-switch
        v-model="switch1"
        inset
        :label="
          index.is_required == true
            ? index.display_label + '*'
            : index.display_label
        "
        :rules="index.is_required == true ? [(v) => !!v || 'required '] : []"
        @change="get_value(switch1, index.display_label, index.key)"
      ></v-switch>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    index: Object,
    formActionType: String,
  },
  data() {
    return {
      switch1: false,
    };
  },
  created() {
    this.switch1 = this.index.value;
  },
  methods: {
    emit_fields(Toggle, index) {
      this.$emit("clicked", Toggle, index);
    },
    get_value(fieldvalue, fieldlabel, fieldKey) {
      var data = {
        label: fieldlabel,
        value: fieldvalue,
        key: fieldKey,
      };

      this.$emit("flipSwitchh", data);
    },
    emit_edit_field(value) {
      this.$emit("editField", value);
    },
  },
};
</script>