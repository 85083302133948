<template>
  <div>
    <v-card class="elevation-0">
      <!-- <v-row>
        <v-col cols="12" md="2">
          <v-select
            dense
            outlined
            label="Filter By"
            v-model="filterCredits"
            :items="filterCreditsItems"
            class="mx-3 mt-n6 mb-n5 mr-n3"
          ></v-select>
        </v-col>
      </v-row> -->
      <div v-if="switchChart1">
        <BarChart
          :height="400"
          v-if="renderComponent1"
          :chartdata="datacollectionBar"
          :options="optionsgraphBar"
          :count="count"
        />
      </div>
      <div v-if="switchChart2">
        <BarChart
          :height="400"
          v-if="renderComponent2"
          :chartdata="datacollectionBar2"
          :options="optionsgraphBar2"
          :count="count"
        />
      </div>
      <div v-if="switchChart3">
        <BarChart
          :height="400"
          v-if="renderComponent3"
          :chartdata="datacollectionBar3"
          :options="optionsgraphBar3"
          :count="count"
        />
      </div>
    </v-card>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import BarChart from "@/components/Charts/BarChart.js";
import { API, graphqlOperation } from "aws-amplify";
import { GetDailyScans, GtMonthlyScans } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
export default {
  components: {
    BarChart,
    SnackBar,
    Overlay,
  },
  props: {
    OrgObject: Object,
    tab: Number,
    openView: String,
    watchCredits: String,
  },
  data: () => ({
    renderComponent1: true,
    renderComponent2: true,
    renderComponent3: true,
    switchChart1: true,
    switchChart2: false,
    switchChart3: false,
    // filterCredits: "count_credits",
    // filterCreditsItems: [
    //   { text: "COUNT CREDITS", value: "count_credits" },
    //   { text: "AVERAGE CREDITS", value: "avg_credits" },
    //   { text: "MONTHLY CREDITS", value: "monthly_credits" },
    // ],
    SnackBarComponent: {},
    overlay: false,
    count: 0,
    datacollectionBar: {
      labels: [],
      datasets: [
        {
          data: [0],
          label: "Total Credits",
          backgroundColor: [],
          borderColor: [],
          fill: true,
        },
        {
          data: [1],
          label: "Face Scan",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [2],
          label: "QR Scan",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },

        // {
        //   data: [1],
        //   label: "Reswipe",
        //   backgroundColor: [],
        //   borderColor: [],
        //   fill: false,
        // },
        {
          data: [3],
          label: "Proxy",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },

        {
          data: [4],
          label: "Forms",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [5],
          label: "Data Correction",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [6],
          label: "RFID",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        // {
        //   data: [4],
        //   label: "Whatsapp",
        //   backgroundColor: [],
        //   borderColor: [],
        //   fill: false,
        // },

        {
          data: [7],
          label: "Beacon",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [8],
          label: "Box",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },

        {
          data: [9],
          label: "Desktop",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [10],
          label: "Simple",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
        {
          data: [11],
          label: "Live Tracking",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
      ],
    },
    optionsgraphBar: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
    },
    datacollectionBar2: {
      labels: [],
      datasets: [
        {
          data: [],
          label: "Average Credits",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
      ],
    },
    optionsgraphBar2: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      scales: {
        xAxes: [
          {
            barPercentage: 0.3,
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
    },
    datacollectionBar3: {
      labels: [],
      datasets: [
        {
          data: [],
          label: "Monthly Credits",
          backgroundColor: [],
          borderColor: [],
          fill: false,
        },
      ],
    },
    optionsgraphBar3: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 0.3,
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  }),
  mounted() {
    if (this.tab == 0 && this.openView == "AVERAGE") {
      this.datacollectionBar2.labels = [];
      this.count = 0;
      this.overlay = true;
      this.$emit("currentView", "AVERAGE");
      this.get_daily_scans_list2();
      this.switchChart1 = false;
      this.switchChart2 = true;
      this.switchChart3 = false;
    } else if (this.tab == 0 && this.openView == "MONTHLY") {
      this.datacollectionBar3.labels = [];
      this.count = 0;
      this.overlay = true;
      this.$emit("currentView", "MONTHLY");
      this.getMonthlyCredits();
      this.switchChart1 = false;
      this.switchChart2 = false;
      this.switchChart3 = true;
      this.forceRerender3();
    } else {
      this.overlay = true;
      this.count = 0;
      this.get_daily_scans_list();
    }
  },
  watch: {
    watchCredits(val) {
      if (val == "avg_credits") {
        this.datacollectionBar2.labels = [];
        this.count = 0;
        this.overlay = true;
        this.$emit("currentView", "AVERAGE");
        this.get_daily_scans_list2();
        this.switchChart1 = false;
        this.switchChart2 = true;
        this.switchChart3 = false;
        this.forceRerender2();
      } else if (val == "monthly_credits") {
        this.datacollectionBar3.labels = [];
        this.count = 0;
        this.overlay = true;
        this.$emit("currentView", "MONTHLY");
        this.getMonthlyCredits();
        this.switchChart1 = false;
        this.switchChart2 = false;
        this.switchChart3 = true;
        this.forceRerender3();
      } else {
        this.datacollectionBar.labels = [];
        this.count = 0;
        this.overlay = true;
        this.$emit("currentView", "DAILY");
        this.get_daily_scans_list();
        this.switchChart1 = true;
        this.switchChart2 = false;
        this.switchChart3 = false;
        this.forceRerender1();
      }
    },
  },

  methods: {
    async get_daily_scans_list() {
      this.DailyScans = [];
      this.isLoading = true;
      this.overlay = true;
      // var year=new Date().getFullYear().toString();.
      var year= new Date().getTime().toString();
      try {
        let result = await API.graphql(
          graphqlOperation(GetDailyScans, {
            input: {
              organisation_id: this.OrgObject.organisation_id,
              month: year,
            },
          })
        );
        // console.log("Current Timestamp: " + new Date().getTime());
// console.log(result);
        this.DailyScans = JSON.parse(result.data.GetDailyScans);
        // console.log(this.DailyScans, " this.DailyScans");
        let cameraArray = [];
        var cameraColor = [];
        var reswipeArray = [];
        var reswipeColor = [];
        var datacorrectionArray = [];
        var datacorrectionColor = [];
        var whatsappColor = [];
        var whatsappArray = [];
        var proxyArray = [];
        var proxyColor = [];
        var totlColor = [];
        var totlArray = [];
        var beaconColor = [];
        var beconArray = [];
        var BoxColor = [];
        var BoxArray = [];
        var faceColor = [];
        var faceArray = [];
        var desktoparray = [];
        var desktopColor = [];
        var formsarray = [];
        var formscolor = [];
        var rfidarray = [];
        var rfidcolor = [];
        var simplearray = [];
        var simplecolor = [];
        var livetrackingarray = [];
        var livetrackingcolor = [];
        this.datacollectionBar.labels = [];
        this.datacollectionBar.datasets[0].data = [];
        this.datacollectionBar.datasets[1].data = [];
        this.datacollectionBar.datasets[2].data = [];
        this.datacollectionBar.datasets[3].data = [];
        this.datacollectionBar.datasets[4].data = [];
        this.datacollectionBar.datasets[5].data = [];
        this.datacollectionBar.datasets[6].data = [];
        this.datacollectionBar.datasets[7].data = [];
        this.datacollectionBar.datasets[8].data = [];
        this.datacollectionBar.datasets[9].data = [];
        this.datacollectionBar.datasets[10].data = [];
        this.datacollectionBar.datasets[11].data = [];
        this.datacollectionBar.labels = [];
        for (let i = 0; i < this.DailyScans.length; i++) {
          // for (let key in this.DailyScans[i]) {
            reswipeArray.push(this.DailyScans[i].reswipe);
            cameraArray.push(this.DailyScans[i].camera);
            datacorrectionArray.push(this.DailyScans[i].datacorrection);
            whatsappArray.push(this.DailyScans[i].whatsapp);
            proxyArray.push(this.DailyScans[i].proxy);
            totlArray.push(this.DailyScans[i].total_swipes);
            beconArray.push(this.DailyScans[i].beacon);
            BoxArray.push(this.DailyScans[i].box);
            faceArray.push(this.DailyScans[i].face);
            desktoparray.push(this.DailyScans[i].desktop);
            formsarray.push(this.DailyScans[i].forms);
            rfidarray.push(this.DailyScans[i].rfid);
            simplearray.push(
              this.DailyScans[i].simple == undefined
                ? 0
                : this.DailyScans[i].simple
            );
            livetrackingarray.push(
              this.DailyScans[i].live == undefined
                ? 0
                : this.DailyScans[i].live
            );

            cameraColor.push("#3cba9f");
            reswipeColor.push("#CD5C5C");
            datacorrectionColor.push("#0000FF");
            whatsappColor.push("#800080");
            proxyColor.push("#FFFF00");
            totlColor.push("#002020");
            beaconColor.push("#800000");
            BoxColor.push("#d90fa6");
            faceColor.push("#F44336");
            desktopColor.push("#FF9800");
            formscolor.push("#d90fa6");
            rfidcolor.push("#827717");
            simplecolor.push("#64DD17");
            livetrackingcolor.push("#BF360C");
            this.datacollectionBar.datasets[0].data = totlArray;
            this.datacollectionBar.datasets[0].borderColor = totlColor;
            this.datacollectionBar.datasets[0].backgroundColor = totlColor;

            this.datacollectionBar.datasets[1].data = faceArray;
            this.datacollectionBar.datasets[1].backgroundColor = faceColor;
            this.datacollectionBar.datasets[1].borderColor = faceColor;

            this.datacollectionBar.datasets[2].data = cameraArray;
            this.datacollectionBar.datasets[2].backgroundColor = cameraColor;
            this.datacollectionBar.datasets[2].borderColor = cameraColor;

            this.datacollectionBar.datasets[3].backgroundColor = proxyColor;
            this.datacollectionBar.datasets[3].borderColor = proxyColor;
            this.datacollectionBar.datasets[3].data = proxyArray;

            this.datacollectionBar.datasets[4].data = formsarray;
            this.datacollectionBar.datasets[4].backgroundColor = formscolor;
            this.datacollectionBar.datasets[4].borderColor = formscolor;
            // this.datacollectionBar.datasets[2].backgroundColor = reswipeColor;
            // this.datacollectionBar.datasets[2].borderColor = reswipeColor;
            // this.datacollectionBar.datasets[2].data = reswipeArray;

            this.datacollectionBar.datasets[5].backgroundColor =
              datacorrectionColor;
            this.datacollectionBar.datasets[5].borderColor =
              datacorrectionColor;
            this.datacollectionBar.datasets[5].data = datacorrectionArray;

            // this.datacollectionBar.datasets[4].backgroundColor = whatsappColor;
            // this.datacollectionBar.datasets[4].borderColor = whatsappColor;
            // this.datacollectionBar.datasets[4].data = whatsappArray;
            this.datacollectionBar.datasets[6].data = rfidarray;
            this.datacollectionBar.datasets[6].backgroundColor = rfidcolor;
            this.datacollectionBar.datasets[6].borderColor = rfidcolor;

            this.datacollectionBar.datasets[7].backgroundColor = beaconColor;
            this.datacollectionBar.datasets[7].borderColor = beaconColor;
            this.datacollectionBar.datasets[7].data = beconArray;

            this.datacollectionBar.datasets[8].data = BoxArray;
            this.datacollectionBar.datasets[8].backgroundColor = BoxColor;
            this.datacollectionBar.datasets[8].borderColor = BoxColor;

            this.datacollectionBar.datasets[9].data = desktoparray;
            this.datacollectionBar.datasets[9].backgroundColor = desktopColor;
            this.datacollectionBar.datasets[9].borderColor = desktopColor;

            this.datacollectionBar.datasets[10].data = simplearray;
            this.datacollectionBar.datasets[10].backgroundColor = simplecolor;
            this.datacollectionBar.datasets[10].borderColor = simplecolor;

            this.datacollectionBar.datasets[11].data = livetrackingarray;
            this.datacollectionBar.datasets[11].backgroundColor =
              livetrackingcolor;
            this.datacollectionBar.datasets[11].borderColor = livetrackingcolor;

// console.log(this.DailyScans[i].date);
            this.datacollectionBar.labels.push(this.DailyScans[i].date);
            // console.log( this.datacollectionBar.labels);
          // }
        }
        this.count = 1;
        this.isLoading = false;
        this.overlay = false;
        this.$forceUpdate();
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
    async get_daily_scans_list2() {
      this.DailyScans = [];
      this.isLoading = true;
      this.overlay = true;
      // var year=new Date().getFullYear().toString();
      var year= new Date().getTime().toString();
      try {
        let result = await API.graphql(
          graphqlOperation(GetDailyScans, {
            input: {
              organisation_id: this.OrgObject.organisation_id,
              month: year,
            },
          })
        );
        this.DailyScans = JSON.parse(result.data.GetDailyScans);
        var AvgArray = [];
        var AvgColor = [];
        this.datacollectionBar2.labels = [];
        this.datacollectionBar2.datasets[0].data = [];
        for (let i = 0; i < this.DailyScans.length; i++) {
          // for (let key in this.DailyScans[i]) {
            AvgArray.push(
              Number(this.DailyScans[i].averageSwipes).toFixed(2)
            );
            AvgColor.push("#00C897");
            this.datacollectionBar2.datasets[0].data = AvgArray;
            this.datacollectionBar2.datasets[0].backgroundColor = AvgColor;
            this.datacollectionBar2.datasets[0].borderColor = AvgColor;
            this.datacollectionBar2.labels.push(this.DailyScans[i].date);
          // }
        }
        this.count = 1;
        this.isLoading = false;
        this.overlay = false;
        this.$forceUpdate();
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
    async getMonthlyCredits() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GtMonthlyScans, {
            input: {
              organisation_id: this.OrgObject.organisation_id,
            },
          })
        );
        let MonthlyScans = JSON.parse(result.data.GtMonthlyScans);
        var MonthlyArray = [];
        var MonthlyColor = [];
        this.datacollectionBar3.datasets[0].data = [];
        this.datacollectionBar3.labels = [];
        for (let i = 0; i < MonthlyScans.length; i++) {
          MonthlyArray.push(Object.values(MonthlyScans[i])[0]);
          MonthlyColor.push("#FC4F4F");
          this.datacollectionBar3.datasets[0].data = MonthlyArray;
          this.datacollectionBar3.datasets[0].backgroundColor = MonthlyColor;
          this.datacollectionBar3.datasets[0].borderColor = MonthlyColor;
          this.datacollectionBar3.labels.push(Object.keys(MonthlyScans[i])[0]);
        }
        this.count = 1;
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log(err);
        this.overlay = false;
      }
    },
    forceRerender1() {
      this.renderComponent1 = false;
      this.$nextTick(() => {
        this.renderComponent1 = true;
      });
    },
    forceRerender2() {
      this.renderComponent2 = false;
      this.$nextTick(() => {
        this.renderComponent2 = true;
      });
    },
    forceRerender3() {
      this.renderComponent3 = false;
      this.$nextTick(() => {
        this.renderComponent3 = true;
      });
    },
  },
};
</script>

<style></style>
