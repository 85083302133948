<template>
  <div>
    <!-- eslint-disable -->
    <v-dialog v-model="previewDialog" persistent max-width="500">
      <v-card flat class="">
        <v-toolbar dense class="gradient-bg elevation-0">
          <v-toolbar-title class="ml-4 white--text">
            Preview Form
          </v-toolbar-title>
          <v-spacer />
          <v-icon class="white--text mr-n3" @click="close_dialog()"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-row no-gutters>
            <v-col
              v-for="(index, Idx) in formFields"
              :key="Idx"
              :cols="Idx === 0 ? '12' : '12 mt-n4'"
              sm="12"
              xs="12"
              md="12"
            >
              <TextField
                :formActionType="formActionType"
                v-if="
                  index.type == 'TEXTFIELD' ||
                  index.type == 'EMAIL' ||
                  index.type == 'NAME'|| index.type == 'MOBILENUMBER'
                "
                :index="index"
              />
              <NumberField
                v-if="index.type == 'NUMBER' "
                :index="index"
                :formActionType="formActionType"
              />
              <CheckboxField
                class="mt-n4"
                v-if="index.type == 'CHECKBOX'"
                :index="index"
                :formActionType="formActionType"
              />
              <TextAreaField
                v-if="index.type == 'PARAGRAPH'"
                :index="index"
                :formActionType="formActionType"
              />
              <DateField
                v-if="index.type == 'DATE'"
                :index="index"
                :formActionType="formActionType"
              />
              <DropdowmField
                v-if="index.type == 'DROPDOWN'"
                :index="index"
                :formActionType="formActionType"
              />
              <TimePicker
                v-if="index.type == 'TIME'"
                :index="index"
                :formActionType="formActionType"
              />
              <RatingFile
                class="mt-n4"
                v-if="index.type == 'RATING'"
                :index="index"
                :formActionType="formActionType"
              />
              <DividerComp
                v-if="index.type == 'DELIMITER'"
                :index="index"
                :formActionType="formActionType"
              />
              <FlipSwitch
                v-if="index.type == 'FLIPSWITCH'"
                :index="index"
                class="mt-n4"
                :formActionType="formActionType"
              />
              <LabelField
              v-if="index.type == 'LABEL'"
              :index="index"
              :formActionType="formActionType"
            />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
/* eslint-disable */
import LabelField from "@/components/FormDesigner/FormFields/LabelField.vue";
import TextField from "@/components/FormDesigner/FormFields/TextField.vue";
import NumberField from "@/components/FormDesigner/FormFields/NumberField.vue";
import CheckboxField from "@/components/FormDesigner/FormFields/CheckboxField.vue";
import TextAreaField from "@/components/FormDesigner/FormFields/TextAreaField.vue";
import DateField from "@/components/FormDesigner/FormFields/DateField.vue";
import DropdowmField from "@/components/FormDesigner/FormFields/DropdowmField.vue";
import TimePicker from "@/components/FormDesigner/FormFields/TimePicker.vue";
import RatingFile from "@/components/FormDesigner/FormFields/RatingFile.vue";
import DividerComp from "@/components/FormDesigner/FormFields/DividerComp.vue";
import FlipSwitch from "@/components/FormDesigner/FormFields/FlipSwitch.vue";

export default {
  props: {
    formFields: Array,
    previewDialog: Boolean,
  },
  components: {
    TextField,
    NumberField,
    CheckboxField,
    TextAreaField,
    DateField,
    DropdowmField,
    TimePicker,
    RatingFile,
    DividerComp,
    FlipSwitch,
    LabelField,
  },
  data() {
    return {
      formActionType: "preview",
    };
  },
  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
      
    // console.log(this.formFields);
    },
  },
};
</script>