<template>
  <div>
    <v-card flat v-if="formActionType != 'initiate'">
      <!-- :flat="formActionType != undefined" -->
      <v-card-text>
        <v-row>
          <v-col cols="11">
            <v-checkbox
              v-model="checkInput"
              readonly
              dense
              :label="
                index.is_required == true
                  ? index.displayLabel + '*'
                  : index.displayLabel
              "
                    @click="emit_edit_field(index)"
            ></v-checkbox>
          </v-col>
          <v-col cols="1">
            <v-btn
              v-if="
                formActionType != 'initiate' &&
                formActionType != 'preview' &&
                formActionType != 'previewproof'
              "
              x-small
              color="red"
              fab
              dark
              right
              @click="emit_fields((Toggle = 1), index)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <!-- absolute -->
          <!-- bottom -->
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat v-else>
      <v-checkbox
        dense
        v-model="checkInput"
        :label="
          index.is_required == true
            ? index.display_label + '*'
            : index.display_label
        "
        :rules="index.is_required == true ? [(v) => !!v || 'required '] : []"
        @change="get_value(checkInput, index.display_label, index.key)"
      ></v-checkbox>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    index: Object,
    formActionType: String,
  },
  data: () => ({
    Toggle: 0,
    checkInput: false,
  }),
  created() {
    this.checkInput = this.index.value;
  },
  methods: {
    emit_fields(Toggle, index) {
      this.$emit("clicked", Toggle, index);
    },
    emit_edit_field(value) {
      this.$emit("editField", value);
    },
    get_value(fieldvalue, fieldlabel, fieldKey) {
      var data = {
        label: fieldlabel,
        value: fieldvalue,
        key: fieldKey,
      };

      this.$emit("checkboxvalue", data);
    },
  },
};
</script>
