var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12","xl":"12","xs":"12"}},[_c('v-data-table',{attrs:{"height":_vm.height,"headers":_vm.scanLogsData,"items":_vm.ScanLogsItems,"dense":"","loading":_vm.isLoading,"loading-text":"Loading... Please wait","options":_vm.pagination,"fixed-header":_vm.fixed},on:{"update:options":[function($event){_vm.pagination=$event},function($event){return _vm.getVerifyPayPagination()}]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v(" No swipes registered yet.")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.swipe_date",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_date2(item.swipe_time_stamp).split(',')[0])}})]}},{key:"item.swipe_time",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_date2(item.swipe_time_stamp).split(',')[1])}})]}},{key:"item.contact_number",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(
            item.user_contact_number
              ? item.user_country_code + '-' + item.user_contact_number
              : 'N/A'
          )}})]}},{key:"item.type",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":item.source == 'CAMERA'
                  ? 'primary'
                  : item.source == 'WHATSAPP'
                  ? 'green'
                  : item.source == 'RESWIPE'
                  ? 'red'
                  : item.source == 'DATACORRECTION'
                  ? 'purple'
                  : item.source == 'PROXY'
                  ? 'yellow'
                  : item.source == 'BEACON'
                  ? 'blue'
                  : 'indigo'},domProps:{"textContent":_vm._s(
                item.source == 'CAMERA'
                  ? 'mdi-camera'
                  : item.source == 'WHATSAPP'
                  ? 'mdi-whatsapp'
                  : item.source == 'RESWIPE'
                  ? 'mdi-redo-variant'
                  : item.source == 'DATACORRECTION'
                  ? 'mdi-human-edit'
                  : item.source == 'PROXY'
                  ? 'mdi-odnoklassniki'
                  : item.source == 'BOX'
                  ? 'mdi-card-account-details-outline'
                  : item.source == 'BEACON'
                  ? 'mdi-bluetooth-audio'
                  : item.source == 'DESKTOP'
                  ? 'mdi-desktop-classic'
                  : item.source == 'FACE'
                  ? 'mdi-account-box-outline'
                  : ''
              )}},on))]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(item.source == "CAMERA" ? "Source: QR" : item.source == "WHATSAPP" ? "Source: Whatsapp" : item.source == "RESWIPE" ? "Source: Re-Scan" : item.source == "DATACORRECTION" ? "Source: Data Correction" : item.source == "PROXY" ? "Source: PROXY" : item.source == "FACE" ? "Source: FACE" : item.source == "BEACON" ? "Source: BEACON" : item.source == "BOX" ? "Source: BOX" : item.source == "DESKTOP" ? "Source: DESKTOP" : "")+" ")])])]}},{key:"item.geo",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('a',{on:{"click":function($event){item.user_lat != undefined ||
                item.user_long != undefined ||
                item.user_lat != null ||
                item.user_long != null
                  ? _vm.view_geo2(item)
                  : ''}}},[_c('v-icon',_vm._g({attrs:{"small":"","color":item.user_lat != undefined ||
                  item.user_long != undefined ||
                  item.user_lat != null ||
                  item.user_long != null
                    ? 'primary'
                    : ''},domProps:{"textContent":_vm._s(
                  item.user_lat != undefined ||
                  item.user_long != undefined ||
                  item.user_lat != null ||
                  item.user_long != null
                    ? 'mdi-map-marker'
                    : 'mdi-map-marker-off'
                )}},on))],1)]}}],null,true)},[(
              item.user_lat != undefined ||
              item.user_long != undefined ||
              item.user_lat != null ||
              item.user_long != null
            )?_c('span',{staticClass:"white--text"},[_vm._v("View Geo")]):_c('span',{staticClass:"white--text"},[_vm._v(" Geo not available")])])]}},{key:"item.box_location",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.box_location)+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.box_location))])])]}},{key:"item.user_name",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.user_name)+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.user_name))])])]}}],null,true)})],1),_c('ViewGeo',{attrs:{"viewGeoDialog":_vm.viewGeoDialog,"geoObject":_vm.geoObject,"forScanLog":_vm.forScanLog},on:{"close_dialog":function($event){_vm.viewGeoDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }