var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.formActionType != 'initiate')?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{staticClass:"FontSize",attrs:{"dense":"","outlined":"","readonly":"","type":"number","label":_vm.index.is_required == true
                ? _vm.index.displayLabel + '*'
                : _vm.index.displayLabel},on:{"click":function($event){return _vm.emit_edit_field(_vm.index)}},model:{value:(_vm.numberInput),callback:function ($$v) {_vm.numberInput=$$v},expression:"numberInput"}})],1),_c('v-col',{attrs:{"cols":"1"}},[(
              _vm.formActionType != 'initiate' &&
              _vm.formActionType != 'preview' &&
              _vm.formActionType != 'previewproof'
            )?_c('v-btn',{attrs:{"x-small":"","color":"red","fab":"","dark":"","right":""},on:{"click":function($event){_vm.emit_fields((_vm.Toggle = 1), _vm.index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)],1)],1):_c('v-card',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"FontSize",attrs:{"dense":"","outlined":"","label":_vm.index.is_required == true
          ? _vm.index.display_label + '*'
          : _vm.index.display_label,"rules":_vm.index.is_required == true ? [function (v) { return !!v || 'required '; }] : []},on:{"keypress":function($event){return _vm.is_Number($event)},"paste":function($event){return _vm.paste_event($event)},"blur":function($event){return _vm.get_value(_vm.numberInput, _vm.index.display_label, _vm.index.key)}},model:{value:(_vm.numberInput),callback:function ($$v) {_vm.numberInput=$$v},expression:"numberInput"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }