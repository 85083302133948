<template>
  <div>
    <v-dialog v-model="changeGstDialog" max-width="700" persistent>
      <v-card>
        <v-toolbar class="gradient-bg" dense>
          <v-toolbar-title class="white--text">
            Change GST - {{ ChgGstObj.organisation_name }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn class="white--text" @click="closeDialog()" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card class="overflow-hidden">
          <v-form ref="form">
            <v-row>
              <v-text-field
                dense
                outlined
                label="Change GST"
                v-model="currentGST"
                rows="2"
                class="mx-7 mt-7"
                :rules="GstRules"
                @keydown.enter="changeGST()"
              ></v-text-field>
            </v-row>
            <v-row justify="center" class="mt-n3">
              <v-btn
                @click="changeGST()"
                class="gradient-bg white--text mb-7"
                small
                >Confirm</v-btn
              >
            </v-row>
          </v-form>
        </v-card>
      </v-card>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { EditOrganisationDetails } from "@/graphql/mutations.js";
import SnackBar from "@/components/SnackBar.vue";

export default {
  components: {
    SnackBar,
  },
  props: {
    changeGstDialog: Boolean,
    ChgGstObj: Object,
  },
  data: () => ({
    overlay: false,
    SnackBarComponent: {},
    currentGST: "",
    GstRules: [
      (v) => !!v || "GST is required",
      // (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),
  watch: {
    changeGstDialog(val) {
      if (val == true) {
        this.currentGST = this.ChgGstObj.organisation_gst_number;
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close_dialog");
    },
    async changeGST() {
      if (this.$refs.form.validate()) {
        try {
          await API.graphql(
            graphqlOperation(EditOrganisationDetails, {
              input: {
                organisation_id: this.ChgGstObj.organisation_id,
                organisation_gst_number: this.currentGST,
              },
            })
          ).then((res) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: JSON.parse(res.data.EditOrganisationDetails)
                .Message,
            };
            this.$emit("re_render");
            this.closeDialog();
          });
        } catch (err) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err,
          };
        }
      }
    },
  },
};
</script>

<style></style>
