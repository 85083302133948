<template>
  <div>
    <v-dialog v-model="viewMemberQRDialog" persistent width="500px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title
          >QR for -
          {{
            userDetailsObj.user_name != undefined
              ? userDetailsObj.user_name
              : userDetailsObj.qr_location_name
          }}</v-toolbar-title
        >
        <v-spacer />
        <v-btn @click="close_dialog()" text
          ><v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="overflow--hidden">
        <v-card-text align="center" justify="center">
          <vue-html2pdf
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :filename="
              userDetailsObj.user_name != undefined
                ? userDetailsObj.user_name
                : userDetailsObj.qr_location_name + ' - QR'
            "
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a6"
            pdf-orientation="portrait"
            :paginate-elements-by-height="800"
            pdf-content-width="420px"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <v-card-text align="center" justify="center">
                <v-img
                  src="@/assets/prezsqu.png"
                  class="mt-n5"
                  height="145"
                  width="145"
                >
                </v-img>
              </v-card-text>
              <v-card-text class="mt-n5" align="center" justify="center">
                <VueQRCodeComponent
                  :text="member_qr"
                  error-level="H"
                  :size="150"
                ></VueQRCodeComponent>
              </v-card-text>
              <v-card-text align="center" justify="center">
                <v-img src="@/assets/prezwww.png" class="mt-n3" max-width="180">
                </v-img
              ></v-card-text>
            </section>
          </vue-html2pdf>
        </v-card-text>
        <v-card-text align="center" justify="center">
          <v-btn
            class="gradient-bg white--text mt-n5 ml-10"
            @click="generateReport()"
            ><v-icon medium class="mr-2">mdi-qrcode-scan</v-icon>Print QR</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueQRCodeComponent from "vue-qr-generator";
import VueHtml2pdf from "vue-html2pdf";
export default {
  props: {
    viewMemberQRDialog: Boolean,
    userDetailsObj: Object,
  },
  components: { VueQRCodeComponent, VueHtml2pdf },
  data: () => ({
    member_qr: "",
  }),
  watch: {
    viewMemberQRDialog(val) {
      if (val == true) {
        this.member_qr =
          this.userDetailsObj.user_id != undefined
            ? `https://www.prezence.app/Apps/myprezence?qr_id=PROXY#${this.userDetailsObj.user_id}`
            : `https://www.prezence.app/Apps/myprezence?qr_id=${this.userDetailsObj.qr_id}`;
      }
    },
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    close_dialog() {
      this.$emit("close_view_qr_dialog");
    },
  },
};
</script>

<style></style>
