var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12","sm":"12","xl":"12","xs":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.locationHeaders,"items":_vm.locationItems,"dense":"","items-per-page":15,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.no_of_source",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary white--text",attrs:{"x-small":""},on:{"click":function($event){return _vm.getLocationSources(item)}}},on),[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.no_of_source)+" ")])])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("View Sources")])])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":item.geo_lat != undefined && item.geo_long != undefined
                  ? 'primary'
                  : ''},domProps:{"textContent":_vm._s(
                item.geo_lat != undefined && item.geo_long != undefined
                  ? 'mdi-map-marker-radius'
                  : 'mdi-map-marker-off'
              )},on:{"click":function($event){item.geo_lat != undefined ||
                item.geo_long != undefined ||
                item.geo_lat != null ||
                item.geo_long != null
                  ? _vm.view_geo(item)
                  : ''}}},on))]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.geo_lat != undefined && item.geo_long != undefined ? "View Geo" : "Geo Not Enabled"))])])]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v("No Data Available")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.location_created_on",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.get_date(item.location_created_on)))])]}}],null,true)})],1),_c('ViewSourcesForLocation',{attrs:{"viewSourceDialog":_vm.viewSourceDialog,"locationObject":_vm.locationObj},on:{"close_view_src_dialog":function($event){_vm.viewSourceDialog = false}}}),_c('ViewGeo',{attrs:{"viewGeoDialog":_vm.viewGeoDialog,"geoObject":_vm.geoObject,"forScanLog":_vm.forScanLog},on:{"close_dialog":function($event){_vm.viewGeoDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }