<template>
  <div>
    <v-dialog v-model="changeCountryDialog" max-width="600" persistent>
      <v-card>
        <v-toolbar class="gradient-bg" dense>
          <v-toolbar-title class="white--text">
            Change Country / State
          </v-toolbar-title>
          <v-spacer />
          <v-btn class="white--text" @click="closeDialog()" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card class="overflow-hidden" height="118px">
          <v-card-text>
            <v-form  ref="form">
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="selectedCountry"
                    :items="countries"
                    item-text="name"
                    item-value="isoCode"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'Required ']"
                    label="Select Country"
                    @change="filterStates"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="selectedState"
                    :items="filteredStates"
                    item-text="name"
                    item-value="name"
                    outlined
                    :rules="[(v) => !!v || 'Required ']"
                    dense
                    label="Select State"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row justify="center" class="mt-n3">
                <v-btn
                  @click="changeRefBy()"
                  class="gradient-bg white--text"
                  small
                  >Confirm</v-btn
                >
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-card>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>
  
  <script>
import { API, graphqlOperation } from "aws-amplify";
import { EditOrganisationDetails } from "@/graphql/mutations.js";
import SnackBar from "@/components/SnackBar.vue";
import CountryList from "@/JsonFiles/CountryList.json";
import { Country, State } from "country-state-city";

export default {
  components: {
    SnackBar,
    CountryList,
  },
  props: {
    changeCountryDialog: Boolean,
    refObj: Object,
  },
  data: () => ({
    overlay: false,
    SnackBarComponent: {},
    countriesData: [],
    selectedItem: "",
    countries: [],
    states: [],
    selectedCountry: "",
    selectedState: "",
  }),
  watch: {
    async changeCountryDialog(val) {
      if (val == true) {
        this.selectItem = [];
        this.countries = Country.getAllCountries();
        // console.log(this.countries);
        this.states = State.getAllStates();
        const matchingCountry = this.countries.find(
          (country) => country.name === this.refObj.organisation_country
        );
        // this.selectedCountry = this.refObj.organisation_country;
        this.selectedCountry = matchingCountry.isoCode;
        // console.log(matchingCountry);
        // console.log(this.countries);
        console.log(this.refObj);
        this.selectedState =
          this.refObj.organisation_state == undefined ||
          this.refObj.organisation_state == "N/A"
            ? ""
            : this.refObj.organisation_state;
      }
    },
  },
  computed: {
    filteredStates() {
      console.log(this.selectedCountry);

      return this.states.filter(
        (state) => state.countryCode === this.selectedCountry
      );
    },
  },
  methods: {
    changeRefBys() {
      const matchingCountry = this.countries.find(
        (country) => country.isoCode === this.selectedCountry
      );
      console.log(matchingCountry);
    },
    filterStates() {
      // Filter states based on the selected country
      this.selectedState = ""; // Reset selected state
    },
    closeDialog() {
      this.$emit("close_dialog");
    },
    async changeRefBy() {
      if (this.$refs.form.validate()) {
      const matchingCountry = this.countries.find(
        (country) => country.isoCode === this.selectedCountry
      );
      console.log(matchingCountry.name);
      try {
        await await API.graphql(
          graphqlOperation(EditOrganisationDetails, {
            input: {
              organisation_id: this.refObj.organisation_id,
              organisation_country: matchingCountry.name,
              organisation_state:
                this.selectedState == "" ? "N/A" : this.selectedState,
            },
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: JSON.parse(res.data.EditOrganisationDetails).Message,
          };
          this.$emit("re_render");
          this.closeDialog();
        });
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText:
            err.errors[0].message ===
            "ValidationException: ExpressionAttributeValues must not be empty"
              ? "Email-ID Already Tagged!"
              : err.errors[0].message,
        };
      }
      }
    },
  },
};
</script>
  
  <style></style>
  