<template>
  <v-row>
    <v-col cols="12" lg="12" md="12" sm="12" xl="12" xs="12">
      <v-data-table
        :headers="locationHeaders"
        :items="locationItems"
        dense
        :items-per-page="15"
        :loading="isLoading"
      >
        <template v-slot:[`item.no_of_source`]="{ item }">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                v-on="on"
                class="primary white--text"
                @click="getLocationSources(item)"
              >
                <span style="font-size: 12px">
                  {{ item.no_of_source }}
                </span>
              </v-btn>
            </template>
            <span class="white--text">View Sources</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon
                @click="
                  item.geo_lat != undefined ||
                  item.geo_long != undefined ||
                  item.geo_lat != null ||
                  item.geo_long != null
                    ? view_geo(item)
                    : ''
                "
                small
                v-on="on"
                :color="
                  item.geo_lat != undefined && item.geo_long != undefined
                    ? 'primary'
                    : ''
                "
                v-text="
                  item.geo_lat != undefined && item.geo_long != undefined
                    ? 'mdi-map-marker-radius'
                    : 'mdi-map-marker-off'
                "
              ></v-icon>
            </template>
            <span class="white--text">{{
              item.geo_lat != undefined && item.geo_long != undefined
                ? "View Geo"
                : "Geo Not Enabled"
            }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`no-data`]>
          <v-alert class="gradient-bg2 white--text">No Data Available</v-alert>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" class="gradient-bg2 white--text">
            No records found!
          </v-alert>
        </template>
        <template v-slot:[`item.location_created_on`]="{ item }">
          <span>{{ get_date(item.location_created_on) }}</span>
        </template>
      </v-data-table>
    </v-col>
    <ViewSourcesForLocation
      :viewSourceDialog="viewSourceDialog"
      :locationObject="locationObj"
      @close_view_src_dialog="viewSourceDialog = false"
    />
    <ViewGeo
      @close_dialog="viewGeoDialog = false"
      :viewGeoDialog="viewGeoDialog"
      :geoObject="geoObject"
      :forScanLog="forScanLog"
    />
  </v-row>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { GetAllLocations } from "@/graphql/queries.js";
import ViewSourcesForLocation from "@/components/Dialogs/Organisations/OrganisationDetails/ViewSourcesForLocation.vue";
import ViewGeo from "@/components/Dialogs/Organisations/ViewGeo.vue";

export default {
  props: {
    OrgObj: Object,
  },
  components: {
    ViewSourcesForLocation,
    ViewGeo,
  },
  data: () => ({
    overlay: false,
    viewGeoDialog: false,
    viewSourceDialog: false,
    locationObj: {},
    geoObject: {},
    isLoading: false,
    locationHeaders: [
      {
        text: "Location Name",
        value: "location_name",
      },
      {
        text: "Created On",
        value: "location_created_on",
      },
      {
        text: "#Sources",
        value: "no_of_source",
      },
      {
        text: "Actions",
        value: "Actions",
      },
    ],
    locationItems: [],
    forScanLog: false,
  }),
  mounted() {
    this.get_locations(this.OrgObj);
  },
  methods: {
    getLocationSources(item) {
      this.locationObj = item;
      this.viewSourceDialog = true;
    },
    view_geo(item) {
      this.forScanLog = false;
      // console.log(item, "geo called!");
      this.geoObject = item;
      this.viewGeoDialog = true;
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    async get_locations(item) {
      this.locationItems = [];
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(GetAllLocations, {
            input: {
              organisation_id: item.organisation_id,
            },
          })
        );
        this.locationItems = JSON.parse(
          result.data.GetAllLocations
        ).totallocations.Items;
        this.isLoading = false;
        this.overlay = false;
        this.$forceUpdate();
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
  },
};
</script>
<style></style>
